 <template>
  <div class="dez-section">
    <div class="box">
      Below is a list of Pokemon that are best to fight if you're chasing after Herba Mystica as they have a chance of dropping every
      Herba Mystica type and may drop more than one.
      <o-collapse class="card" animation="slide" :open="basketOpen" @open="basketOpen = true" v-for="raid in raids" :key="raid.stars">
        <template #trigger="props">
          <div class="card-header" role="button">
            <p class="card-header-title">
              {{ raid.area }} {{ raid.stars }} Star Raids
            </p>
            <a class="card-header-icon">
              <o-icon :icon="props.open ? 'caret-up' : 'caret-down'"> </o-icon>
            </a>
          </div>
        </template>
        <div class="card-content content smaller-content">
          <ul class="item-wrapper content">
            <li v-for="(pokemon, idx) in raid.pokemon" :key="`${raid.stars}-${idx}`">
              {{ pokemon }}
            </li>

          </ul>
        </div>
      </o-collapse>
    </div>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      raids: [
        {
          area: 'Paldea',
          stars: 6,
          pokemon: [
            'Amoongus',
            'Blissey',
            'Cetitan',
            'Dondozo',
            'Farigiraf',
            'Vaporeon'
          ]
        },
        {
          area: 'Kitakami',
          stars: 6,
          pokemon: [
            'Amoongus',
            'Blissey',
            'Cetitan',
            'Dondozo',
            'Farigiraf',
            'Vaporeon'
          ]
        },
        {
          area: 'Blueberry Academy',
          stars: 6,
          pokemon: [
            'Alolan Muk',
            'Galarian Slowbro',
            'Lapras',
            'Kingdra',
            'Malamar',
            'Porygon 2'
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.power-heading {
  position: relative;
  top: 6px;
}

.smaller-content {
  padding-top: .3rem;
  padding-left: .3rem;
}
</style>
