<template>
  <div>
    <o-collapse class="card" animation="slide" v-for="(item) of items" :key="item.id"
      :open="isOpen == item.id" @open="isOpen = item.id">
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">
            {{ GetText(item.id) }}
          </p>
          <a class="card-header-icon">
            <o-icon :icon="props.open ? 'caret-up' : 'caret-down'"> </o-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <div v-for="(city, key) in ItemsStoresByCity(item.id)" :key="`${item.id}_${key}`">
            <h3>{{ GetText(key) }}</h3>
            <div v-for="(store, idx) in city" :key="`${item.id}_${key}_${idx}`">
              {{ GetText(store.building) }}{{ store.location ? `, ${GetText(store.location)}`: ''}}
            </div>
          </div>
        </div>
      </div>
    </o-collapse>
  </div>
</template>

<script>
import { GetText } from '../store/TextHelper'
import { GetStoresWithItem } from '../store/StoreHelper'
import { GroupByField } from '../store/Utils'
import { ref } from 'vue'
export default {
  props: ['items'],
  data: function () {
    return {
      isOpen: ref(-1)
    }
  },
  methods: {
    GetText,
    GetStoresWithItem,
    ItemsStoresByCity: function (itemId) {
      return GroupByField(this.GetStoresWithItem(itemId), 'city')
    }
  }
}
</script>

<style scoped>
h3 {
  margin-bottom: 0px;
}
</style>
