import stores from './stores.mjs'
import { GroupByField } from './Utils'
import { GetText } from './TextHelper'

export function GetStoresWithItem (itemId) {
  return stores.filter(store => store.items.indexOf(itemId) !== -1)
}

function overlap (l1, l2) {
  return l1.filter(v => l2.indexOf(v) !== -1)
}

function missing (target, list) {
  return target.filter(v => !list.has(v))
}

export function GetCitiesWithItems (items) {
  var stores = GroupByField(GetStoresWithItems(items), 'city')
  var perfectCityFound = false
  var cities = Object.keys(stores).map(city => {
    var itemSet = new Set()
    stores[city].forEach(store => {
      store.items.forEach(v => itemSet.add(v))
    })

    let score = items.length - itemSet.size
    if (score !== 0) {
      score = items.length - itemSet.size
    } else {
      perfectCityFound = true
    }
    return {
      id: city,
      stores: stores[city],
      missing: missing(items, itemSet),
      score
    }
  })
  if (perfectCityFound) {
    cities = cities.filter(v => v.score === 0)
  }
  cities.sort((a, b) => a.score - b.score)
  cities.forEach(city => {
    city.name = GetText(city.id)
    city.stores.forEach(store => {
      store.building = GetText(store.building)
      if (store.location) {
        store.location = GetText(store.location)
      }
      store.items = store.items.map(id => ({ id, text: GetText(id) }))
      store.items.sort((a, b) => a.name < b.name ? -1 : 1)
    })
    city.stores.sort((a, b) => b.items.length - a.items.length)
  })
  return cities
}

export function GetStoresWithItems (items) {
  const result = []
  stores.forEach(store => {
    const r = {
      id: store.id,
      city: store.city,
      building: store.building,
      location: store.location,
      items: overlap(store.items, items)
    }

    if (r.items.length > 0) {
      result.push(r)
    }
  })
  return result
}
