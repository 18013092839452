<template>
  <div class="content">
    <o-collapse class="card" animation="slide" :open="basketOpen" @open="basketOpen = true">
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">
            Shopping Basket
          </p>
          <a class="card-header-icon">
            <o-icon :icon="props.open ? 'caret-up' : 'caret-down'"> </o-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <div class="item-wrapper">
            <o-checkbox v-model="SelectedItems" :native-value="item.id" v-for="item in Items" :key="item.id" class="item">
              {{ GetText(item.id) }}
            </o-checkbox>
          </div>
        </div>
      </div>
    </o-collapse>
    <o-collapse class="card" animation="slide" :open="tripsOpen" @open="tripsOpen = true">
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">
            Shopping Trip
          </p>
          <a class="card-header-icon">
            <o-icon :icon="props.open ? 'caret-up' : 'caret-down'"> </o-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <div v-if="trips.length === 0">
            Select some items to find some shopping locations
          </div>
          <div v-for="(city, idx) in trips" :key="idx">
            <div class="city-header">{{ city.name }}</div>
            <div v-if="city.missing.length !== 0" class="missing">
              This city does not have {{ city.missing.join(', ') }}
            </div>
            <div class="left-wrapper">
              <div v-for="(store) in city.stores" :key="store.id">
                <div class="store-header">{{ store.building }}{{ store.location ? `, ${store.location}` : '' }}</div>
                <div class="items">{{ store.items.map(v => v.text).join(', ') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </o-collapse>
  </div>
</template>

<script>
import { GetText } from '../store/TextHelper'
import { GetCitiesWithItems } from '../store/StoreHelper'
import Items from '../store/items.mjs'
export default {
  data: function () {
    return {
      isOpen: 'basket',
      Items: Items.filter(item => item.categories.indexOf('INGREDIENT') !== -1),
      SelectedItems: [],
      basketOpen: true,
      tripsOpen: true
    }
  },
  methods: {
    GetText,
    GetCitiesWithItems
  },
  computed: {
    trips: function () {
      return GetCitiesWithItems(this.SelectedItems)
    }
  }
}
</script>

<style scoped>
.city-header {
  font-size: 1.5em;
  font-weight: bold;
}

.store-header {
  font-weight: bolder;
}

.city-wrapper {
  margin-left: 5px;
  border-left-color: gray;
  border-left-width: 1px;
  border-left-style: solid;
}

.item-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.item {
  min-width: 175px;
  padding: 5px;
}
</style>
