export default [
  {
    "meal": "Jambon-Beurre",
    "number": "1",
    "location": "START",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "GROUND",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "BUG",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Bitter Jambon-Beurre",
    "number": "2",
    "location": "BITTER_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "BUG",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Sweet Jambon-Beurre",
    "number": "3",
    "location": "SWEET_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "BUG",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Salty Jambon-Beurre",
    "number": "4",
    "location": "SALTY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "BUG",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Sour Jambon-Beurre",
    "number": "5",
    "location": "SOUR_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "TEENSY",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "BUG",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Spicy Jamon-Beurre",
    "number": "6",
    "location": "SPICY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "BUG",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Legendary Bitter Sandwich",
    "number": "7",
    "location": "BITTER_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GRASS",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Legendary Salty Sandwich",
    "number": "8",
    "location": "SALTY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GRASS",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Legendary Sweet Sandwich",
    "number": "9",
    "location": "SWEET_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GRASS",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Legendary Sour Sandwich",
    "number": "10",
    "location": "SOUR_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "TEENSY",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GRASS",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Legendary Spicy Sandwich",
    "number": "11",
    "location": "SPICY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GRASS",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Jam Sandwich",
    "number": "12",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Jam Sandwich",
    "number": "13",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "FIGHTING",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GHOST",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Jam Sandwich",
    "number": "14",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Jam Sandwich",
    "number": "15",
    "location": "SWEET_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "FIGHTING",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Peanut Butter Sandwich",
    "number": "16",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "ELECTRIC",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "BUG",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Peanut Butter Sandwich",
    "number": "17",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "ELECTRIC",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "NORMAL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Peanut Butter Sandwich",
    "number": "18",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "BUG",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Peanut Butter Sandwich",
    "number": "19",
    "location": "SPICY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ELECTRIC",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "BUG",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Pickle Sandwich",
    "number": "20",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "GHOST",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Pickle Sandwich",
    "number": "21",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ITEM",
        "type": "FIGHTING",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "GHOST",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Pickle Sandwich",
    "number": "22",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ITEM",
        "type": "FIGHTING",
        "level": "2"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FIGHTING",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Pickle Sandwich",
    "number": "23",
    "location": "SOUR_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "FIGHTING",
        "level": "2"
      },
      {
        "power": "TEENSY",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Marmalade Sandwich",
    "number": "24",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ITEM",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Marmalade Sandwich",
    "number": "25",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Marmalade Sandwich",
    "number": "26",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "FIGHTING",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Marmalade Sandwich",
    "number": "27",
    "location": "SALTY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "FIGHTING",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Herbed-Sausage Sandwich",
    "number": "28",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GROUND",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Herbed-Sausage Sandwich",
    "number": "29",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "FIGHTING",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Herbed-Sausage Sandwich",
    "number": "30",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Herbed-Sausage Sandwich",
    "number": "31",
    "location": "BITTER_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "FIGHTING",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Curry-and-Rice-Style",
    "number": "32",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "FIGHTING",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Curry-and-Rice-Style",
    "number": "33",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "NORMAL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Curry-and-Rice-Style",
    "number": "34",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "FAIRY",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Curry-and-Rice-Style",
    "number": "35",
    "location": "SPICY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "FAIRY",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Dessert Sandwich",
    "number": "36",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "FLYING",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "ICE",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Dessert Sandwich",
    "number": "37",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "FLYING",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "ICE",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Dessert Sandwich",
    "number": "38",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ITEM",
        "type": "FLYING",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Dessert Sandwich",
    "number": "39",
    "location": "SWEET_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "FLYING",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FIGHTING",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Tropical Sandwich",
    "number": "40",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Tropical Sandwich",
    "number": "41",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "WATER",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Tropical Sandwich",
    "number": "42",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "WATER",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GROUND",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Tropical Sandwich",
    "number": "43",
    "location": "SOUR_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "WATER",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "GROUND",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Avocado Sandwich",
    "number": "44",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "ELECTRIC",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "DARK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Avocado Sandwich",
    "number": "45",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "DARK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Avocado Sandwich",
    "number": "46",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Avocado Sandwich",
    "number": "47",
    "location": "SALTY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "DRAGON",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Noodle Sandwich",
    "number": "48",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "POISON",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "GROUND",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Noodle Sandwich",
    "number": "49",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GROUND",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Noodle Sandwich",
    "number": "50",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "FIRE",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "ELECTRIC",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Noodle Sandwich",
    "number": "51",
    "location": "BITTER_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "ELECTRIC",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Potato Salad Sandwich",
    "number": "52",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "GHOST",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Potato Salad Sandwich",
    "number": "53",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "BUG",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Potato Salad Sandwich",
    "number": "54",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "DRAGON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Potato Salad Sandwich",
    "number": "55",
    "location": "SWEET_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Zesty Sandwich",
    "number": "56",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "WATER",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Zesty Sandwich",
    "number": "57",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "PSYCHIC",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "WATER",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Zesty Sandwich",
    "number": "58",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "PSYCHIC",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "FIGHTING",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Zesty Sandwich",
    "number": "59",
    "location": "SOUR_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "PSYCHIC",
        "level": "2"
      },
      {
        "power": "TEENSY",
        "type": "FIGHTING",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Egg Sandwich",
    "number": "60",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "FLYING",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "ROCK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Egg Sandwich",
    "number": "61",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "FLYING",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "ROCK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Egg Sandwich",
    "number": "62",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "FLYING",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "STEEL",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "ROCK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Egg Sandwich",
    "number": "63",
    "location": "SALTY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "FLYING",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Class Bocadillo",
    "number": "64",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "GRASS",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "FIRE",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Class Bocadillo",
    "number": "65",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "GRASS",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIRE",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Class Bocadillo",
    "number": "66",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FIRE",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GHOST",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Class Bocadillo",
    "number": "67",
    "location": "BITTER_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Refreshing Sandwich",
    "number": "68",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "DRAGON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Refreshing Sandwich",
    "number": "69",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Refreshing Sandwich",
    "number": "70",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Refreshing Sandwich",
    "number": "71",
    "location": "SPICY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "BLT Sandwich",
    "number": "72",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "GRASS",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great BLT Sandwich",
    "number": "73",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "FAIRY",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra BLT Sandwich",
    "number": "74",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FAIRY",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master BLT Sandwich",
    "number": "75",
    "location": "SWEET_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FAIRY",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Fried Fillet Sandwich",
    "number": "76",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "STEEL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Fried Fillet Sandwich",
    "number": "77",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "STEEL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Fried Fillet Sandwich",
    "number": "78",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "STEEL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Fried Fillet Sandwich",
    "number": "79",
    "location": "SPICY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "STEEL",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Ham Sandwich",
    "number": "80",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GROUND",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Ham Sandwich",
    "number": "81",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FLYING",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GROUND",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Ham Sandwich",
    "number": "82",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FAIRY",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "GRASS",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Ham Sandwich",
    "number": "83",
    "location": "SALTY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FAIRY",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "GRASS",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Cheese Sandwich",
    "number": "84",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "WATER",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "STEEL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Cheese Sandwich",
    "number": "85",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "BUG",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Cheese Sandwich",
    "number": "86",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "ELECTRIC",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "WATER",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Cheese Sandwich",
    "number": "87",
    "location": "SALTY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "ELECTRIC",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "WATER",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Hamburger Patty Sandwich",
    "number": "88",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "STEEL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Hamburger Patty Sandwich",
    "number": "89",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "PSYCHIC",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "STEEL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Hamburger Patty Sandwich",
    "number": "90",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "PSYCHIC",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "STEEL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Hamburger Patty Sandwich",
    "number": "91",
    "location": "SWEET_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "PSYCHIC",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "STEEL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Smoky Sandwich",
    "number": "92",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "PSYCHIC",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Smoky Sandwich",
    "number": "93",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "DARK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Smoky Sandwich",
    "number": "94",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "PSYCHIC",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GHOST",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Smoky Sandwich",
    "number": "95",
    "location": "SALTY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "PSYCHIC",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "GHOST",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Fruit Sandwich",
    "number": "96",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ITEM",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "GROUND",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Fruit Sandwich",
    "number": "97",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ITEM",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "POISON",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Fruit Sandwich",
    "number": "98",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ITEM",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Fruit Sandwich",
    "number": "99",
    "location": "SWEET_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FLYING",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Variety Sandwich",
    "number": "100",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "BUG",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Variety Sandwich",
    "number": "101",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "TEENSY",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "DARK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Variety Sandwich",
    "number": "102",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "STEEL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Variety Sandwich",
    "number": "103",
    "location": "BITTER_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "STEEL",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Klawf Claw Sandwich",
    "number": "104",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "GRASS",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "ICE",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Klawf Claw Sandwich",
    "number": "105",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "ICE",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Klawf Claw Sandwich",
    "number": "106",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "ELECTRIC",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Klawf Claw Sandwich",
    "number": "107",
    "location": "SPICY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "ELECTRIC",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Sweet Sandwich",
    "number": "108",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "FLYING",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Sweet Sandwich",
    "number": "109",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "FLYING",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Sweet Sandwich",
    "number": "110",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "FLYING",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "NORMAL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Sweet Sandwich",
    "number": "111",
    "location": "SOUR_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ELECTRIC",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "FIGHTING",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Vegetable Sandwich",
    "number": "112",
    "location": "MOTHER",
    "powers": [
      {
        "power": "TEENSY",
        "type": "POISON",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "WATER",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "BUG",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Vegetable Sandwich",
    "number": "113",
    "location": "MOTHER",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "TEENSY",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "POISON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Vegetable Sandwich",
    "number": "114",
    "location": "MOTHER",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "TEENSY",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "POISON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Vegetable Sandwich",
    "number": "115",
    "location": "MOTHER_SALTY",
    "powers": [
      {
        "power": "TITLE",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "POISON",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Hefty Sandwich",
    "number": "116",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "BUG",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Hefty Sandwich",
    "number": "117",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "DARK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Hefty Sandwich",
    "number": "118",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "STEEL",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "DARK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Hefty Sandwich",
    "number": "119",
    "location": "SOUR_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "TEENSY",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Five-Alarm Sandwich",
    "number": "120",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "POISON",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "DRAGON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Five-Alarm Sandwich",
    "number": "121",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "DRAGON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Five-Alarm Sandwich",
    "number": "122",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "FIRE",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "DRAGON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Five-Alarm Sandwich",
    "number": "123",
    "location": "SPICY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Nouveau Veggie Sandwich",
    "number": "124",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ITEM",
        "type": "ELECTRIC",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "PSYCHIC",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Nouveau Veggie Sandwich",
    "number": "125",
    "location": "MOTHER",
    "powers": [
      {
        "power": "ITEM",
        "type": "ELECTRIC",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "PSYCHIC",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Nouveau Veggie Sandwich",
    "number": "126",
    "location": "MOTHER",
    "powers": [
      {
        "power": "TEENSY",
        "type": "ELECTRIC",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FAIRY",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "PSYCHIC",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Nouveau Veggie Sandwich",
    "number": "127",
    "location": "MOTHER_SWEET",
    "powers": [
      {
        "power": "TITLE",
        "type": "ELECTRIC",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FAIRY",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "PSYCHIC",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Spicy-Sweet Sandwich",
    "number": "128",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "POISON",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "GROUND",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Spicy-Sweet Sandwich",
    "number": "129",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GROUND",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Spicy-Sweet Sandwich",
    "number": "130",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GROUND",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Spicy-Sweet Sandwich",
    "number": "131",
    "location": "BITTER_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Decadent Sandwich",
    "number": "132",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "ICE",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "DARK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Decadent Sandwich",
    "number": "133",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "DARK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Decadent Sandwich",
    "number": "134",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "ICE",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Decadent Sandwich",
    "number": "135",
    "location": "SPICY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "ICE",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Tofu Sandwich",
    "number": "136",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "GRASS",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Tofu Sandwich",
    "number": "137",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "RAID",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "GRASS",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Tofu Sandwich",
    "number": "138",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "RAID",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "GRASS",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Tofu Sandwich",
    "number": "139",
    "location": "SALTY_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "FIGHTING",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Curry-and-Noodle Sandwich",
    "number": "140",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "ELECTRIC",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "ROCK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Curry-and-Noodle Sandwich",
    "number": "141",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ITEM",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "ELECTRIC",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Curry-and-Noodle Sandwich",
    "number": "142",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GRASS",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "ICE",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Curry-and-Noodle Sandwich",
    "number": "143",
    "location": "SWEET_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Tower Sandwich",
    "number": "144",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "GHOST",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Tower Sandwich",
    "number": "145",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "EXP",
        "type": "STEEL",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "ICE",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Tower Sandwich",
    "number": "146",
    "location": "SANDWICH_SHOP",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "STEEL",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Master Tower Sandwich",
    "number": "147",
    "location": "BITTER_HERBA",
    "powers": [
      {
        "power": "TITLE",
        "type": "STEEL",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "FIGHTING",
        "level": "2"
      }
    ]
  },
  {
    "meal": "Sushi Sandwich",
    "number": "148",
    "location": "SUSHI_OFICIANADO_CASSEROYA",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "NORMAL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Great Sushi Sandwich",
    "number": "149",
    "location": "SUSHI_OFICIANADO_CASSEROYA",
    "powers": [
      {
        "power": "EXP",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "NORMAL",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Ultra Sushi Sandwich",
    "number": "150",
    "location": "SUSHI_OFICIANADO_CASSEROYA",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "NORMAL",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "DARK",
        "level": "1"
      }
    ]
  },
  {
    "meal": "Master Sushi Sandwich",
    "number": "151",
    "location": "SUSHI_OFICIANADO_CASSEROYA",
    "powers": [
      {
        "power": "TITLE",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "TEENSY",
        "type": "DARK",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "NORMAL",
        "level": "2"
      }
    ]
  }
]