<template>
  <div class="content">
    <o-tabs
      :open="isOpen == index"
      @open="isOpen = index"
    >
      <o-tab-item
        v-for="(ev, index) of EVSettings"
        :key="index"
        :label="GetText(ev.id)"
      >
        <div class="content">
          <div class="box">
            <h2 class="subtitle">Items</h2>
            <div class="left-wrapper">
              <div class="table">
                <div class="table-row">
                  <div class="table-cell">
                    Power Item
                  </div>
                  <div>
                    {{ GetText(ev.powerItem) }}
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell">
                    Vitamin
                  </div>
                  <div>
                    {{ GetText(ev.vitamin) }}
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell">
                    Feather (+1)
                  </div>
                  <div>
                    {{ GetText(ev.feather) }}
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell">
                    Reset Berry
                  </div>
                  <div>
                    {{ GetText(ev.berry) }}
                  </div>
                </div>
              </div>
            </div>
            <h2 class="subtitle">Locations</h2>
            <div v-for="loc in ev.locations" :key="`${ev.id}-${loc.location}`">
              <h3 class="">{{ GetText(loc.location) }}</h3>
              <div class="left-wrapper">
                <div class="table">
                  <div class="table-row">
                    <div class="table-cell sandwich-header">
                      Recommended Sandwich:
                    </div>
                    <div class="table-cell">
                      {{ GetText(loc.sandwich) }}
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="table-cell sandwich-header">
                      Pokemon
                    </div>
                    <div class="table-cell">
                      <div v-for="(pokemon, idx) in loc.pokemon" :key="`${ev.id}-${loc.location}-${idx}`">
                        {{ GetText(pokemon) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </o-tab-item>
    </o-tabs>
  </div>
</template>

<script>
import Items from '../store/items.mjs'
import { ref } from 'vue'
import { GetText } from '../store/TextHelper'
import EVSettings from '../store/ev-training'
export default {
  data: function () {
    const categories = [
      'BATTLE',
      'EVOLUTION',
      'GENERAL',
      'INGREDIENT',
      'BREEDING'
    ]
    return {
      index: -1,
      items: Items,
      isOpen: ref(0),
      EVSettings,
      categories,
      filters: categories.reduce((p, n) => {
        p[n] = ''
        return p
      }, {})
    }
  },
  methods: {
    GetText
  }
}
</script>

<style scoped>
.table-cell {
  padding: 5px 15px 5px 5px;
}
</style>
