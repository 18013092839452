<template>
  <div class="dez-section">
    <div class="box">
      This was thrown together by me, Dezzles, because I honestly just got sick of having to work out where to buy things
      in Pokemon Scarlet and Violet.<br/>
      If you find any issues or have feature requests please reach out to me on <a href="https://twitter.com/Dezzles">Twitter @Dezzles</a>
    </div>
  </div>
</template>

<script>

export default {
}
</script>
