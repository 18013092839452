 <template>
  <div class="dez-section">
    <div class="box">
      <power-search title="Search Sandwich Powers" v-model="search" />
      <h3 class="subtitle">Results ({{ matchedMeals.length }})</h3>
      <div v-for="(m, idx) in matchedMeals" :key="`result-${idx}`">
          <Meal :meal="m" :isSandwich="true" />
      </div>
      <div v-if="matchedMeals.length === 0">
        No sandwiches matched
      </div>
    </div>
  </div>
</template>

<script>
import Meals from '../store/sandwiches'
import Meal from '../components/Meal'
import PowerSearch from '../components/PowerSearch'

export default {
  components: {
    Meal,
    PowerSearch
  },
  data: function () {
    const kvp = (a, b) => ({ id: a, val: b })
    return {
      powers: [
        kvp('HUMUNGO', 'Humungo'),
        kvp('EXP', 'Exp'),
        kvp('ENCOUNTER', 'Encounter'),
        kvp('CATCHING', 'Catching'),
        kvp('ITEM', 'Item'),
        kvp('EGG', 'Egg'),
        kvp('TEENSY', 'Teensy')
      ],
      types: [
        kvp('NORMAL', 'Normal'),
        kvp('FIRE', 'Fire'),
        kvp('WATER', 'Water'),
        kvp('GRASS', 'Grass'),
        kvp('ELECTRIC', 'Electric'),
        kvp('ICE', 'Ice'),
        kvp('FIGHTING', 'Fighting'),
        kvp('POISON', 'Poison'),
        kvp('GROUND', 'Ground'),
        kvp('FLYING', 'Flying'),
        kvp('PSYCHIC', 'Psychic'),
        kvp('BUG', 'Bug'),
        kvp('ROCK', 'Rock'),
        kvp('GHOST', 'Ghost'),
        kvp('DARK', 'Dark'),
        kvp('DRAGON', 'Dragon'),
        kvp('STEEL', 'Steel'),
        kvp('FAIRY', 'Fairy')
      ],
      Meals,
      search: {
        level1: 'any',
        level2: 'any',
        level3: 'any',
        type1: 'any',
        type2: 'any',
        type3: 'any',
        power1: 'any',
        power2: 'any',
        power3: 'any'
      }
    }
  },
  computed: {
    matchedMeals: function () {
      const criteria = [
        { power: this.search.power1, type: this.search.type1, level: this.search.level1 },
        { power: this.search.power2, type: this.search.type2, level: this.search.level2 },
        { power: this.search.power3, type: this.search.type3, level: this.search.level3 }
      ]
      const matches = (source, target) => {
        if (source.level !== 'any' && source.level !== target.level) {
          return false
        }
        if (source.power !== 'any' && source.power !== target.power) {
          return false
        }
        if (source.power !== 'EGG' && source.type !== 'any' && source.type !== target.type) {
          return false
        }
        return true
      }
      return this.Meals.filter(meal => {
        var hasMatch = 0
        criteria.forEach(crit => {
          var hasAnyMatch = meal.powers.reduce((p, n) => p || matches(crit, n), false)
          if (hasAnyMatch) {
            ++hasMatch
          }
        })
        return hasMatch === 3
      })
    }
  }
}
</script>

<style scoped>
.power-heading {
  position: relative;
  top: 6px;
}
</style>
