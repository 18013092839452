export default [
  {
    id: 'HP',
    powerItem: 'POWERWEIGHT',
    feather: 'HEALTHFEATHER',
    vitamin: 'HPUP',
    berry: 'POMEG_BERRY',
    locations: [
      {
        location: 'SOUTHPROVINCE_AREA1',
        pokemon: [
          'AZURILL',
          'LECHONK',
          'PALDEANWOOPER'
        ],
        sandwich: 'HAMSANDWICH_80'
      }
    ]
  },
  {
    id: 'ATTACK',
    powerItem: 'POWERBRACER',
    feather: 'MUSCLEFEATHER',
    vitamin: 'PROTEIN',
    berry: 'KELPSY_BERRY',
    locations: [
      {
        location: 'SOUTHPROVINCE_AREA3',
        pokemon: [
          'SHINX',
          'YUNGOOS'
        ],
        sandwich: 'AVOCADOSANDWICH_44'
      }
    ]
  },
  {
    id: 'DEFENSE',
    powerItem: 'POWERBELT',
    feather: 'RESISTFEATHER',
    vitamin: 'IRON',
    berry: 'QUALOT_BERRY',
    locations: [
      {
        location: 'SOUTHPROVINCE_AREA1',
        pokemon: [
          'SCATTERBUG',
          'TAROUNTULA'
        ],
        sandwich: 'JAMBONBUERRE_1'
      }
    ]
  },
  {
    id: 'SPECIALATTACK',
    powerItem: 'POWERLENS',
    feather: 'GENIUSFEATHER',
    vitamin: 'CALCIUM',
    berry: 'HONDEW_BERRY',
    locations: [
      {
        location: 'SOUTHPROVINCE_AREA1',
        pokemon: [
          'PSYDUCK'
        ],
        sandwich: 'ZESTYSANDWICH_56'
      }
    ]
  },
  {
    id: 'SPECIALDEFENSE',
    powerItem: 'POWERBAND',
    feather: 'CLEVERFEATHER',
    vitamin: 'ZINC',
    berry: 'GREPA_BERRY',
    locations: [
      {
        location: 'SOUTHPROVINCE_AREA3',
        pokemon: [
          'SPOINK'
        ],
        sandwich: 'SMOKYSANDWICH_92'
      }
    ]
  },
  {
    id: 'SPEED',
    powerItem: 'POWERANKLET',
    feather: 'SWIFTFEATHER',
    vitamin: 'CARBOS',
    berry: 'TAMATO_BERRY',
    locations: [
      {
        location: 'SOUTHPROVINCE_AREA1',
        pokemon: [
          'FLETCHLING',
          'ROOKIDEE'
        ],
        sandwich: 'EGG_SANDWICH_60'
      }
    ]
  }
]
