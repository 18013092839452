<template>
  <div>
    <h1 class="title">{{ title }}</h1>
    <div class="search-table" label="Simple">
      <div class="search-table-row">
        <div class="search-table-cell power-heading">
          Power 1
        </div>
        <div class="search-table-cell">
          <o-select placeholder="Select a Power" v-model="search.power1">
            <option selected="selected" value="any">Any</option>
            <option v-for="power in powers" :key="`${power.id}1`" :value="power.id">{{ power.val }}</option>
          </o-select>
        </div>
        <div class="search-table-cell">
          <o-select placeholder="Select a Type" v-model="search.type1">
            <option selected="selected" value="any">Any</option>
            <option v-for="type in types" :key="`${type.id}1`" :value="type.id">{{ type.val }}</option>
          </o-select>
        </div>
        <div class="search-table-cell">
          <o-select placeholder="Select a level" v-model="search.level1">
            <option selected="selected" value="any">Any</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </o-select>
        </div>
      </div>
      <div class="search-table-row">
        <div class="search-table-cell power-heading">
          Power 2
        </div>
        <div class="search-table-cell">
          <o-select placeholder="Select a Power" v-model="search.power2">
            <option selected="selected" value="any">Any</option>
            <option v-for="power in powers" :key="`${power.id}2`" :value="power.id">{{ power.val }}</option>
          </o-select>
        </div>
        <div class="search-table-cell">
          <o-select placeholder="Select a Type" v-model="search.type2">
            <option selected="selected" value="any">Any</option>
            <option v-for="type in types" :key="`${type.id}2`" :value="type.id">{{ type.val }}</option>
          </o-select>
        </div>
        <div class="search-table-cell">
          <o-select placeholder="Select a level" v-model="search.level2">
            <option selected="selected" value="any">Any</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </o-select>
        </div>
      </div>
      <div class="search-table-row">
        <div class="search-table-cell">
          <div class="power-heading">
            Power 3
          </div>
        </div>
        <div class="search-table-cell">
          <o-select placeholder="Select a Power" v-model="search.power3">
            <option selected="selected" value="any">Any</option>
            <option v-for="power in powers" :key="`${power.id}3`" :value="power.id">{{ power.val }}</option>
          </o-select>
        </div>
        <div class="search-table-cell">
          <o-select placeholder="Select a Type" v-model="search.type3">
            <option selected="selected" value="any">Any</option>
            <option v-for="type in types" :key="`${type.id}3`" :value="type.id">{{ type.val }}</option>
          </o-select>
        </div>
        <div class="search-table-cell">
          <o-select placeholder="Select a level" v-model="search.level3">
            <option selected="selected" value="any">Any</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </o-select>
        </div>
      </div>
      <o-button variant="danger" @click="resetSearch">Reset</o-button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  props: {
    title: {
      type: String
    },
    modelValue: {
      type: Object
    }
  },
  data: function () {
    const kvp = (a, b) => ({ id: a, val: b })
    return {
      powers: [
        kvp('HUMUNGO', 'Humungo'),
        kvp('EXP', 'Exp'),
        kvp('ENCOUNTER', 'Encounter'),
        kvp('CATCHING', 'Catching'),
        kvp('ITEM', 'Item'),
        kvp('EGG', 'Egg'),
        kvp('TEENSY', 'Teensy')
      ],
      types: [
        kvp('NORMAL', 'Normal'),
        kvp('FIRE', 'Fire'),
        kvp('WATER', 'Water'),
        kvp('GRASS', 'Grass'),
        kvp('ELECTRIC', 'Electric'),
        kvp('ICE', 'Ice'),
        kvp('FIGHTING', 'Fighting'),
        kvp('POISON', 'Poison'),
        kvp('GROUND', 'Ground'),
        kvp('FLYING', 'Flying'),
        kvp('PSYCHIC', 'Psychic'),
        kvp('BUG', 'Bug'),
        kvp('ROCK', 'Rock'),
        kvp('GHOST', 'Ghost'),
        kvp('DARK', 'Dark'),
        kvp('DRAGON', 'Dragon'),
        kvp('STEEL', 'Steel'),
        kvp('FAIRY', 'Fairy')
      ]
    }
  },
  emits: ['update:modelValue'],

  setup (props, { emit }) {
    const search = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      search
    }
  },
  methods: {
    resetSearch: function () {
      this.search.level1 = 'any'
      this.search.level2 = 'any'
      this.search.level3 = 'any'
      this.search.type1 = 'any'
      this.search.type2 = 'any'
      this.search.type3 = 'any'
      this.search.power1 = 'any'
      this.search.power2 = 'any'
      this.search.power3 = 'any'
    }
  }
}
</script>

<style>
.power-heading {
  position: relative;
  top: 6px;
}
</style>
