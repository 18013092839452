export default [
  {
    "city": "MONTENEVERA",
    "building": "CHANSEYSUPPLY",
    "location": "",
    "items": [
      "PPUP",
      "ABILITYCAPSULE",
      "HPUP",
      "PROTEIN",
      "IRON",
      "CALCIUM",
      "ZINC",
      "CARBOS",
      "XATTACK",
      "XDEFENSE",
      "XSPATK",
      "XSPDEF",
      "XSPEED",
      "XACCURACY",
      "DIREHIT",
      "GUARDSPEC",
      "ENERGYPOWDER",
      "ENERGYROOT",
      "HEALPOWDER",
      "REVIVALHERB",
      "SERIOUSMINT",
      "LONELYMINT",
      "ADMANTMINT",
      "NAUGHTYMINT",
      "BRAVEMINT",
      "BOLDMINT",
      "IMPISHMINT",
      "LAXMINT",
      "RELAXEDMINT",
      "MODESTMINT",
      "MILDMINT",
      "RASHMINT",
      "QUIETMINT",
      "CALMMINT",
      "GENTLEMINT",
      "CAREFULMINT",
      "SASSYMINT",
      "TIMIDMINT",
      "HASTYMINT",
      "JOLLYMINT",
      "NAIVEMINT"
    ],
    "id": "MONTENEVERA_CHANSEYSUPPLY"
  },
  {
    "city": "MONTENEVERA",
    "building": "DELICIOSO",
    "location": "",
    "items": [
      "EGG",
      "POTATOSALAD",
      "CHORIZO",
      "BACON",
      "POTATOTORTILLA",
      "PROSCIUTTO",
      "CUCUMBER",
      "AVOCADO",
      "REDONION",
      "HAMBURGER",
      "SMOKEDFILLET",
      "WATERCRESS",
      "VINEGAR",
      "CHILISAUCE",
      "BASIL",
      "GREENBELLPEPPER",
      "REDBELLPEPPER",
      "YELLOWBELLPEPPER",
      "FRIEDFILLET",
      "HERBEDSAUSAGE"
    ],
    "id": "MONTENEVERA_DELICIOSO"
  },
  {
    "city": "LEVINCIA",
    "building": "SURECANS",
    "location": "",
    "items": [
      "BANANA",
      "STRAWBERRY",
      "TOMATO",
      "PICKLE",
      "APPLE",
      "CHERRYTOMATOES",
      "KIWI",
      "JALAPENO",
      "PINEAPPLE",
      "NOODLES",
      "RICE"
    ],
    "id": "LEVINCIA_SURECANS"
  },
  {
    "city": "LEVINCIA",
    "building": "DELIBIRDPRESENTS",
    "location": "",
    "items": [
      "SOFTSAND",
      "BLACKGLASSES",
      "BLACKBELT",
      "NEVERMELTICE",
      "TWISTEDSPOON",
      "METALCOAT",
      "LOADEDDICE",
      "SHELLBELL",
      "METRONOME",
      "SCOPELENS",
      "WIDELENS",
      "ZOOMLENS",
      "SAFETYGOGGLES",
      "PROTECTIVEPADS",
      "HEAVYDUTYBOOTS",
      "COVERTCLOAK",
      "REDCARD",
      "EJECTBUTTON",
      "EJECTPACK",
      "ADRENALINEORB",
      "ROOMSERVICE",
      "IRONBALL",
      "TOXICORB",
      "FLAMEORB",
      "LAGGINTAIL",
      "BLACKSLUDGE",
      "STICKYBARB",
      "RINGTARGET",
      "BINDINGBAND",
      "BOTTLECAP",
      "FIRESTONE",
      "THUNDERSTONE",
      "LEAFSTONE",
      "WATERSTONE",
      "SWEETAPPLE",
      "TARTAPPLE",
      "REDNECTAR",
      "YELLOWNECTAR",
      "PINKNECTAR",
      "PURPLENECTAR",
      "SMOKEBALL",
      "SOOTHEBELL",
      "EVERSTONE",
      "POWERWEIGHT",
      "POWERBRACER",
      "POWERBELT",
      "POWERLENS",
      "POWERBAND",
      "POWERANKLET"
    ],
    "id": "LEVINCIA_DELIBIRDPRESENTS"
  },
  {
    "city": "LEVINCIA",
    "building": "CHANSEYSUPPLY",
    "location": "",
    "items": [
      "PPUP",
      "ABILITYCAPSULE",
      "HPUP",
      "PROTEIN",
      "IRON",
      "CALCIUM",
      "ZINC",
      "CARBOS",
      "XATTACK",
      "XDEFENSE",
      "XSPATK",
      "XSPDEF",
      "XSPEED",
      "XACCURACY",
      "DIREHIT",
      "GUARDSPEC",
      "ENERGYPOWDER",
      "ENERGYROOT",
      "HEALPOWDER",
      "REVIVALHERB",
      "SERIOUSMINT",
      "LONELYMINT",
      "ADMANTMINT",
      "NAUGHTYMINT",
      "BRAVEMINT",
      "BOLDMINT",
      "IMPISHMINT",
      "LAXMINT",
      "RELAXEDMINT",
      "MODESTMINT",
      "MILDMINT",
      "RASHMINT",
      "QUIETMINT",
      "CALMMINT",
      "GENTLEMINT",
      "CAREFULMINT",
      "SASSYMINT",
      "TIMIDMINT",
      "HASTYMINT",
      "JOLLYMINT",
      "NAIVEMINT"
    ],
    "id": "LEVINCIA_CHANSEYSUPPLY"
  },
  {
    "city": "LEVINCIA",
    "building": "AQUIESTASUPERMARKET",
    "location": "",
    "items": [
      "MAYONNAISE",
      "KETCHUP",
      "MUSTARD",
      "SALT",
      "PEPPER",
      "BUTTER",
      "LETTUCE",
      "TOMATO",
      "ONION",
      "PICKLE",
      "HAM",
      "CREAMCHEESE",
      "JAM",
      "OLIVEOIL",
      "CHEESE",
      "YOGURT",
      "WHIPPEDCREAM",
      "CHERRYTOMATOES",
      "CUCUMBER",
      "AVOCADO",
      "REDONION",
      "VINEGAR",
      "WATERCRESS",
      "CHILISAUCE",
      "BASIL",
      "GREENBELLPEPPER",
      "REDBELLPEPPER",
      "YELLOWBELLPEPPER",
      "MARMALADE",
      "PEANUTBUTTER",
      "WASABI",
      "TOFU",
      "HORSERADISH",
      "CURRYPOWDER",
      "KLAWFSTICK"
    ],
    "id": "LEVINCIA_AQUIESTASUPERMARKET"
  },
  {
    "city": "LEVINCIA",
    "building": "ARTISANBAKERY",
    "location": "",
    "items": [
      "HAM",
      "LETTUCE",
      "ONION",
      "MAYONNAISE",
      "KETCHUP",
      "MUSTARD",
      "SALT",
      "PEPPER",
      "BUTTER",
      "CREAMCHEESE",
      "JAM",
      "OLIVEOIL",
      "CHEESE",
      "YOGURT",
      "WHIPPEDCREAM",
      "PEANUTBUTTER",
      "MARMALADE"
    ],
    "id": "LEVINCIA_ARTISANBAKERY"
  },
  {
    "city": "LEVINCIA",
    "building": "DELICIOSO",
    "location": "",
    "items": [
      "EGG",
      "POTATOSALAD",
      "CHORIZO",
      "BACON",
      "POTATOTORTILLA",
      "PROSCIUTTO",
      "CUCUMBER",
      "AVOCADO",
      "REDONION",
      "HAMBURGER",
      "SMOKEDFILLET",
      "WATERCRESS",
      "VINEGAR",
      "CHILISAUCE",
      "BASIL",
      "GREENBELLPEPPER",
      "REDBELLPEPPER",
      "YELLOWBELLPEPPER",
      "FRIEDFILLET",
      "HERBEDSAUSAGE"
    ],
    "id": "LEVINCIA_DELICIOSO"
  },
  {
    "city": "CORTONDO",
    "building": "DELICIOSO",
    "location": "",
    "items": [
      "EGG",
      "POTATOSALAD",
      "CHORIZO",
      "BACON",
      "POTATOTORTILLA",
      "PROSCIUTTO",
      "CUCUMBER",
      "AVOCADO",
      "REDONION",
      "HAMBURGER",
      "SMOKEDFILLET",
      "WATERCRESS",
      "VINEGAR",
      "CHILISAUCE",
      "BASIL",
      "GREENBELLPEPPER",
      "REDBELLPEPPER",
      "YELLOWBELLPEPPER",
      "FRIEDFILLET",
      "HERBEDSAUSAGE"
    ],
    "id": "CORTONDO_DELICIOSO"
  },
  {
    "city": "CORTONDO",
    "building": "ARTISANBAKERY",
    "location": "",
    "items": [
      "HAM",
      "LETTUCE",
      "ONION",
      "MAYONNAISE",
      "KETCHUP",
      "MUSTARD",
      "SALT",
      "PEPPER",
      "BUTTER",
      "CREAMCHEESE",
      "JAM",
      "OLIVEOIL",
      "CHEESE",
      "YOGURT",
      "WHIPPEDCREAM",
      "PEANUTBUTTER",
      "MARMALADE"
    ],
    "id": "CORTONDO_ARTISANBAKERY"
  },
  {
    "city": "CASCARAFFA",
    "building": "DELIBIRDPRESENTS",
    "location": "LOWER",
    "items": [
      "MAGNET",
      "HARDSTONE",
      "POISONBARB",
      "SPELLTAG",
      "DRAGONFANG",
      "NORMALGEM",
      "LEFTOVERS",
      "BIGROOT",
      "BRIGHTPOWDER",
      "LIGHTCLAY",
      "UTILITYUMBRELLA",
      "TERRAINEXTENDER",
      "AIRBALLOON",
      "SHEDSHELL",
      "WHITEHERB",
      "MENTALHERB",
      "POWERHERB",
      "MIRRORHERB",
      "ABSORBBULB",
      "CELLBATTERY",
      "LUMINOUSMOSS",
      "SNOWBALL",
      "HEATROCK",
      "DAMPROCK",
      "SMOOTHROCK",
      "ICYROCK",
      "ELECTRICSEED",
      "PSYCHICSEED",
      "MISTYSEED",
      "GRASSYSEED",
      "BOTTLECAP",
      "FIRESTONE",
      "THUNDERSTONE",
      "LEAFSTONE",
      "WATERSTONE",
      "SWEETAPPLE",
      "TARTAPPLE",
      "REDNECTAR",
      "YELLOWNECTAR",
      "PINKNECTAR",
      "PURPLENECTAR",
      "SMOKEBALL",
      "SOOTHEBELL",
      "EVERSTONE",
      "POWERWEIGHT",
      "POWERBRACER",
      "POWERBELT",
      "POWERLENS",
      "POWERBAND",
      "POWERANKLET"
    ],
    "id": "CASCARAFFA_DELIBIRDPRESENTS_LOWER"
  },
  {
    "city": "CASCARAFFA",
    "building": "DELICIOSO",
    "location": "",
    "items": [
      "EGG",
      "POTATOSALAD",
      "CHORIZO",
      "BACON",
      "POTATOTORTILLA",
      "PROSCIUTTO",
      "CUCUMBER",
      "AVOCADO",
      "REDONION",
      "HAMBURGER",
      "SMOKEDFILLET",
      "WATERCRESS",
      "VINEGAR",
      "CHILISAUCE",
      "BASIL",
      "GREENBELLPEPPER",
      "REDBELLPEPPER",
      "YELLOWBELLPEPPER",
      "FRIEDFILLET",
      "HERBEDSAUSAGE"
    ],
    "id": "CASCARAFFA_DELICIOSO"
  },
  {
    "city": "CASCARAFFA",
    "building": "SURECANS",
    "location": "",
    "items": [
      "BANANA",
      "STRAWBERRY",
      "TOMATO",
      "PICKLE",
      "APPLE",
      "CHERRYTOMATOES",
      "KIWI",
      "JALAPENO",
      "PINEAPPLE",
      "NOODLES",
      "RICE"
    ],
    "id": "CASCARAFFA_SURECANS"
  },
  {
    "city": "CASCARAFFA",
    "building": "CHANSEYSUPPLY",
    "location": "",
    "items": [
      "PPUP",
      "ABILITYCAPSULE",
      "HPUP",
      "PROTEIN",
      "IRON",
      "CALCIUM",
      "ZINC",
      "CARBOS",
      "XATTACK",
      "XDEFENSE",
      "XSPATK",
      "XSPDEF",
      "XSPEED",
      "XACCURACY",
      "DIREHIT",
      "GUARDSPEC",
      "ENERGYPOWDER",
      "ENERGYROOT",
      "HEALPOWDER",
      "REVIVALHERB",
      "SERIOUSMINT",
      "LONELYMINT",
      "ADMANTMINT",
      "NAUGHTYMINT",
      "BRAVEMINT",
      "BOLDMINT",
      "IMPISHMINT",
      "LAXMINT",
      "RELAXEDMINT",
      "MODESTMINT",
      "MILDMINT",
      "RASHMINT",
      "QUIETMINT",
      "CALMMINT",
      "GENTLEMINT",
      "CAREFULMINT",
      "SASSYMINT",
      "TIMIDMINT",
      "HASTYMINT",
      "JOLLYMINT",
      "NAIVEMINT"
    ],
    "id": "CASCARAFFA_CHANSEYSUPPLY"
  },
  {
    "city": "CASCARAFFA",
    "building": "DELIBIRDPRESENTS",
    "location": "MIDDLE",
    "items": [
      "MAGNET",
      "HARDSTONE",
      "POISONBARB",
      "SPELLTAG",
      "DRAGONFANG",
      "NORMALGEM",
      "LEFTOVERS",
      "BIGROOT",
      "BRIGHTPOWDER",
      "LIGHTCLAY",
      "UTILITYUMBRELLA",
      "TERRAINEXTENDER",
      "AIRBALLOON",
      "SHEDSHELL",
      "WHITEHERB",
      "MENTALHERB",
      "POWERHERB",
      "MIRRORHERB",
      "ABSORBBULB",
      "CELLBATTERY",
      "LUMINOUSMOSS",
      "SNOWBALL",
      "HEATROCK",
      "DAMPROCK",
      "SMOOTHROCK",
      "ICYROCK",
      "ELECTRICSEED",
      "PSYCHICSEED",
      "MISTYSEED",
      "GRASSYSEED",
      "BOTTLECAP",
      "FIRESTONE",
      "THUNDERSTONE",
      "LEAFSTONE",
      "WATERSTONE",
      "SWEETAPPLE",
      "TARTAPPLE",
      "REDNECTAR",
      "YELLOWNECTAR",
      "PINKNECTAR",
      "PURPLENECTAR",
      "SMOKEBALL",
      "SOOTHEBELL",
      "EVERSTONE",
      "POWERWEIGHT",
      "POWERBRACER",
      "POWERBELT",
      "POWERLENS",
      "POWERBAND",
      "POWERANKLET"
    ],
    "id": "CASCARAFFA_DELIBIRDPRESENTS_MIDDLE"
  },
  {
    "city": "CASCARAFFA",
    "building": "ARTISANBAKERY",
    "location": "",
    "items": [
      "HAM",
      "LETTUCE",
      "ONION",
      "MAYONNAISE",
      "KETCHUP",
      "MUSTARD",
      "SALT",
      "PEPPER",
      "BUTTER",
      "CREAMCHEESE",
      "JAM",
      "OLIVEOIL",
      "CHEESE",
      "YOGURT",
      "WHIPPEDCREAM",
      "PEANUTBUTTER",
      "MARMALADE"
    ],
    "id": "CASCARAFFA_ARTISANBAKERY"
  },
  {
    "city": "MESAGOZA",
    "building": "ARTISANBAKERY",
    "location": "NORTH",
    "items": [
      "HAM",
      "LETTUCE",
      "ONION",
      "MAYONNAISE",
      "KETCHUP",
      "MUSTARD",
      "SALT",
      "PEPPER",
      "BUTTER",
      "CREAMCHEESE",
      "JAM",
      "OLIVEOIL",
      "CHEESE",
      "YOGURT",
      "WHIPPEDCREAM",
      "PEANUTBUTTER",
      "MARMALADE"
    ],
    "id": "MESAGOZA_ARTISANBAKERY_NORTH"
  },
  {
    "city": "MESAGOZA",
    "building": "SURECANS",
    "location": "WEST",
    "items": [
      "BANANA",
      "STRAWBERRY",
      "TOMATO",
      "PICKLE",
      "APPLE",
      "CHERRYTOMATOES",
      "KIWI",
      "JALAPENO",
      "PINEAPPLE",
      "NOODLES",
      "RICE"
    ],
    "id": "MESAGOZA_SURECANS_WEST"
  },
  {
    "city": "MESAGOZA",
    "building": "DELICIOSO",
    "location": "WEST",
    "items": [
      "EGG",
      "POTATOSALAD",
      "CHORIZO",
      "BACON",
      "POTATOTORTILLA",
      "PROSCIUTTO",
      "CUCUMBER",
      "AVOCADO",
      "REDONION",
      "HAMBURGER",
      "SMOKEDFILLET",
      "WATERCRESS",
      "VINEGAR",
      "CHILISAUCE",
      "BASIL",
      "GREENBELLPEPPER",
      "REDBELLPEPPER",
      "YELLOWBELLPEPPER",
      "FRIEDFILLET",
      "HERBEDSAUSAGE"
    ],
    "id": "MESAGOZA_DELICIOSO_WEST"
  },
  {
    "city": "MESAGOZA",
    "building": "ARTISANBAKERY",
    "location": "WEST",
    "items": [
      "HAM",
      "LETTUCE",
      "ONION",
      "MAYONNAISE",
      "KETCHUP",
      "MUSTARD",
      "SALT",
      "PEPPER",
      "BUTTER",
      "CREAMCHEESE",
      "JAM",
      "OLIVEOIL",
      "CHEESE",
      "YOGURT",
      "WHIPPEDCREAM",
      "PEANUTBUTTER",
      "MARMALADE"
    ],
    "id": "MESAGOZA_ARTISANBAKERY_WEST"
  },
  {
    "city": "MESAGOZA",
    "building": "DELIBIRDPRESENTS",
    "items": [
      "MIRACLESEED",
      "CHARCOAL",
      "MYSTICWATER",
      "SILKSCARF",
      "SHARPBEAK",
      "SILVERPOWDER",
      "MUSCLEBAND",
      "WISEGLASSES",
      "PUNCHINGGLOVE",
      "EXPERTBELT",
      "FOCUSBAND",
      "FOCUSSASH",
      "CHOICEBAND",
      "CHOICESPECS",
      "CHOICESCARF",
      "LIFEORB",
      "ABILITYSHIELD",
      "CLEARAMULET",
      "ROCKYHELMET",
      "EVIOLITE",
      "ASSAULTVEST",
      "QUICKCLAW",
      "RAZORCLAW",
      "GRIPCLAW",
      "KINGSROCK",
      "WEAKNESSPOLICY",
      "BLUNDERPOLICY",
      "THROATSPRAY",
      "DESTINYKNOT",
      "BOTTLECAP",
      "FIRESTONE",
      "THUNDERSTONE",
      "LEAFSTONE",
      "WATERSTONE",
      "SWEETAPPLE",
      "TARTAPPLE",
      "REDNECTAR",
      "YELLOWNECTAR",
      "PINKNECTAR",
      "PURPLENECTAR",
      "SMOKEBALL",
      "SOOTHEBELL",
      "EVERSTONE",
      "POWERWEIGHT",
      "POWERBRACER",
      "POWERBELT",
      "POWERLENS",
      "POWERBAND",
      "POWERANKLET"
    ],
    "id": "MESAGOZA_DELIBIRDPRESENTS"
  },
  {
    "city": "MESAGOZA",
    "building": "ARTISANBAKERY",
    "location": "FARWEST",
    "items": [
      "HAM",
      "LETTUCE",
      "ONION",
      "MAYONNAISE",
      "KETCHUP",
      "MUSTARD",
      "SALT",
      "PEPPER",
      "BUTTER",
      "CREAMCHEESE",
      "JAM",
      "OLIVEOIL",
      "CHEESE",
      "YOGURT",
      "WHIPPEDCREAM",
      "PEANUTBUTTER",
      "MARMALADE"
    ],
    "id": "MESAGOZA_ARTISANBAKERY_FARWEST"
  },
  {
    "city": "MESAGOZA",
    "building": "DELICIOSO",
    "location": "FARWEST",
    "items": [
      "EGG",
      "POTATOSALAD",
      "CHORIZO",
      "BACON",
      "POTATOTORTILLA",
      "PROSCIUTTO",
      "CUCUMBER",
      "AVOCADO",
      "REDONION",
      "HAMBURGER",
      "SMOKEDFILLET",
      "WATERCRESS",
      "VINEGAR",
      "CHILISAUCE",
      "BASIL",
      "GREENBELLPEPPER",
      "REDBELLPEPPER",
      "YELLOWBELLPEPPER",
      "FRIEDFILLET",
      "HERBEDSAUSAGE"
    ],
    "id": "MESAGOZA_DELICIOSO_FARWEST"
  },
  {
    "city": "MESAGOZA",
    "building": "DELIBIRDPRESENTS",
    "location": "SOUTHWEST",
    "items": [
      "MIRACLESEED",
      "CHARCOAL",
      "MYSTICWATER",
      "SILKSCARF",
      "SHARPBEAK",
      "SILVERPOWDER",
      "MUSCLEBAND",
      "WISEGLASSES",
      "PUNCHINGGLOVE",
      "EXPERTBELT",
      "FOCUSBAND",
      "FOCUSSASH",
      "CHOICEBAND",
      "CHOICESPECS",
      "CHOICESCARF",
      "LIFEORB",
      "ABILITYSHIELD",
      "CLEARAMULET",
      "ROCKYHELMET",
      "EVIOLITE",
      "ASSAULTVEST",
      "QUICKCLAW",
      "RAZORCLAW",
      "GRIPCLAW",
      "KINGSROCK",
      "WEAKNESSPOLICY",
      "BLUNDERPOLICY",
      "THROATSPRAY",
      "DESTINYKNOT",
      "BOTTLECAP",
      "FIRESTONE",
      "THUNDERSTONE",
      "LEAFSTONE",
      "WATERSTONE",
      "SWEETAPPLE",
      "TARTAPPLE",
      "REDNECTAR",
      "YELLOWNECTAR",
      "PINKNECTAR",
      "PURPLENECTAR",
      "SMOKEBALL",
      "SOOTHEBELL",
      "EVERSTONE",
      "POWERWEIGHT",
      "POWERBRACER",
      "POWERBELT",
      "POWERLENS",
      "POWERBAND",
      "POWERANKLET"
    ],
    "id": "MESAGOZA_DELIBIRDPRESENTS_SOUTHWEST"
  },
  {
    "city": "MESAGOZA",
    "building": "CHANSEYSUPPLY",
    "items": [
      "PPUP",
      "ABILITYCAPSULE",
      "HPUP",
      "PROTEIN",
      "IRON",
      "CALCIUM",
      "ZINC",
      "CARBOS",
      "XATTACK",
      "XDEFENSE",
      "XSPATK",
      "XSPDEF",
      "XSPEED",
      "XACCURACY",
      "DIREHIT",
      "GUARDSPEC",
      "ENERGYPOWDER",
      "ENERGYROOT",
      "HEALPOWDER",
      "REVIVALHERB",
      "SERIOUSMINT",
      "LONELYMINT",
      "ADMANTMINT",
      "NAUGHTYMINT",
      "BRAVEMINT",
      "BOLDMINT",
      "IMPISHMINT",
      "LAXMINT",
      "RELAXEDMINT",
      "MODESTMINT",
      "MILDMINT",
      "RASHMINT",
      "QUIETMINT",
      "CALMMINT",
      "GENTLEMINT",
      "CAREFULMINT",
      "SASSYMINT",
      "TIMIDMINT",
      "HASTYMINT",
      "JOLLYMINT",
      "NAIVEMINT"
    ],
    "id": "MESAGOZA_CHANSEYSUPPLY"
  },
  {
    "city": "MESAGOZA",
    "building": "DELICIOSO",
    "items": [
      "EGG",
      "POTATOSALAD",
      "CHORIZO",
      "BACON",
      "POTATOTORTILLA",
      "PROSCIUTTO",
      "CUCUMBER",
      "AVOCADO",
      "REDONION",
      "HAMBURGER",
      "SMOKEDFILLET",
      "WATERCRESS",
      "VINEGAR",
      "CHILISAUCE",
      "BASIL",
      "GREENBELLPEPPER",
      "REDBELLPEPPER",
      "YELLOWBELLPEPPER",
      "FRIEDFILLET",
      "HERBEDSAUSAGE"
    ],
    "id": "MESAGOZA_DELICIOSO"
  },
  {
    "city": "MESAGOZA",
    "building": "DELIBIRDPRESENTS",
    "location": "SOUTH",
    "items": [
      "MIRACLESEED",
      "CHARCOAL",
      "MYSTICWATER",
      "SILKSCARF",
      "SHARPBEAK",
      "SILVERPOWDER",
      "MUSCLEBAND",
      "WISEGLASSES",
      "PUNCHINGGLOVE",
      "EXPERTBELT",
      "FOCUSBAND",
      "FOCUSSASH",
      "CHOICEBAND",
      "CHOICESPECS",
      "CHOICESCARF",
      "LIFEORB",
      "ABILITYSHIELD",
      "CLEARAMULET",
      "ROCKYHELMET",
      "EVIOLITE",
      "ASSAULTVEST",
      "QUICKCLAW",
      "RAZORCLAW",
      "GRIPCLAW",
      "KINGSROCK",
      "WEAKNESSPOLICY",
      "BLUNDERPOLICY",
      "THROATSPRAY",
      "DESTINYKNOT",
      "BOTTLECAP",
      "FIRESTONE",
      "THUNDERSTONE",
      "LEAFSTONE",
      "WATERSTONE",
      "SWEETAPPLE",
      "TARTAPPLE",
      "REDNECTAR",
      "YELLOWNECTAR",
      "PINKNECTAR",
      "PURPLENECTAR",
      "SMOKEBALL",
      "SOOTHEBELL",
      "EVERSTONE",
      "POWERWEIGHT",
      "POWERBRACER",
      "POWERBELT",
      "POWERLENS",
      "POWERBAND",
      "POWERANKLET"
    ],
    "id": "MESAGOZA_DELIBIRDPRESENTS_SOUTH"
  },
  {
    "city": "MESAGOZA",
    "building": "CHANSEYSUPPLY",
    "location": "EAST",
    "items": [
      "PPUP",
      "ABILITYCAPSULE",
      "HPUP",
      "PROTEIN",
      "IRON",
      "CALCIUM",
      "ZINC",
      "CARBOS",
      "XATTACK",
      "XDEFENSE",
      "XSPATK",
      "XSPDEF",
      "XSPEED",
      "XACCURACY",
      "DIREHIT",
      "GUARDSPEC",
      "ENERGYPOWDER",
      "ENERGYROOT",
      "HEALPOWDER",
      "REVIVALHERB",
      "SERIOUSMINT",
      "LONELYMINT",
      "ADMANTMINT",
      "NAUGHTYMINT",
      "BRAVEMINT",
      "BOLDMINT",
      "IMPISHMINT",
      "LAXMINT",
      "RELAXEDMINT",
      "MODESTMINT",
      "MILDMINT",
      "RASHMINT",
      "QUIETMINT",
      "CALMMINT",
      "GENTLEMINT",
      "CAREFULMINT",
      "SASSYMINT",
      "TIMIDMINT",
      "HASTYMINT",
      "JOLLYMINT",
      "NAIVEMINT"
    ],
    "id": "MESAGOZA_CHANSEYSUPPLY_EAST"
  },
  {
    "city": "MESAGOZA",
    "building": "SURECANS",
    "location": "EAST",
    "items": [
      "BANANA",
      "STRAWBERRY",
      "TOMATO",
      "PICKLE",
      "APPLE",
      "CHERRYTOMATOES",
      "KIWI",
      "JALAPENO",
      "PINEAPPLE",
      "NOODLES",
      "RICE"
    ],
    "id": "MESAGOZA_SURECANS_EAST"
  }
]