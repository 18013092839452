import Text from './text.mjs'

const TextMap = Text.reduce((p, n) => {
  p[n.id] = n
  return p
}, {})

export default Text
export function GetText (id) {
  if (TextMap[id]) {
    return TextMap[id].english
  }
  return `UnknownId:${id}`
}
