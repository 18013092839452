export default [
  {
    "meal": "Mint Chocolate Ice Cream",
    "cost": "950",
    "powers": [
      {
        "power": "RAID",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sugar-N-Ice 2"
      }
    ]
  },
  {
    "meal": "Fizzy-Pop Ice Cream",
    "cost": "900",
    "powers": [
      {
        "power": "EXP",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "WATER",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "FIRE",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sugar-N-Ice 2"
      }
    ]
  },
  {
    "meal": "Strawberry Ice Cream",
    "cost": "900",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "WATER",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "ROCK",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sugar-N-Ice 2"
      }
    ]
  },
  {
    "meal": "Mango Ice Cream",
    "cost": "900",
    "powers": [
      {
        "power": "TEENSY",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "ELECTRIC",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sugar-N-Ice 2"
      }
    ]
  },
  {
    "meal": "Nacli Salt Ice Cream",
    "cost": "900",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "ICE",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "GRASS",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "DARK",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sugar-N-Ice 2"
      }
    ]
  },
  {
    "meal": "Teriyaki Ice Cream",
    "cost": "1150",
    "powers": [
      {
        "power": "ITEM",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sugar-N-Ice 2"
      }
    ]
  },
  {
    "meal": "Zesty Sandwich",
    "cost": "1750",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "WATER",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Alfornada",
        "store": "Every Wich Way"
      },
      {
        "city": "Porto Marinada",
        "store": "Every Wich Way"
      },
      {
        "city": "Medali",
        "store": "Every Wich Way"
      },
      {
        "city": "Montenevera",
        "store": "Every Wich Way"
      },
      {
        "city": "Zapapico",
        "store": "Every Wich Way"
      },
      {
        "city": "Levincia",
        "store": "Every Wich Way"
      },
      {
        "city": "Cascarafa",
        "store": "Every Wich Way"
      },
      {
        "city": "Mesagoza",
        "store": "Every Wich Way"
      }
    ]
  },
  {
    "meal": "Jam Sandwich",
    "cost": "850",
    "powers": [
      {
        "power": "CATCHING",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Alfornada",
        "store": "Every Wich Way"
      },
      {
        "city": "Porto Marinada",
        "store": "Every Wich Way"
      },
      {
        "city": "Medali",
        "store": "Every Wich Way"
      },
      {
        "city": "Montenevera",
        "store": "Every Wich Way"
      },
      {
        "city": "Zapapico",
        "store": "Every Wich Way"
      },
      {
        "city": "Levincia",
        "store": "Every Wich Way"
      },
      {
        "city": "Cascarafa",
        "store": "Every Wich Way"
      },
      {
        "city": "Mesagoza",
        "store": "Every Wich Way"
      }
    ]
  },
  {
    "meal": "Tropical Sandwich",
    "cost": "1450",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Alfornada",
        "store": "Every Wich Way"
      },
      {
        "city": "Porto Marinada",
        "store": "Every Wich Way"
      },
      {
        "city": "Medali",
        "store": "Every Wich Way"
      },
      {
        "city": "Montenevera",
        "store": "Every Wich Way"
      },
      {
        "city": "Zapapico",
        "store": "Every Wich Way"
      },
      {
        "city": "Levincia",
        "store": "Every Wich Way"
      },
      {
        "city": "Cascarafa",
        "store": "Every Wich Way"
      },
      {
        "city": "Mesagoza",
        "store": "Every Wich Way"
      }
    ]
  },
  {
    "meal": "Marmalade Sandwich",
    "cost": "950",
    "powers": [
      {
        "power": "ITEM",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Alfornada",
        "store": "Every Wich Way"
      },
      {
        "city": "Porto Marinada",
        "store": "Every Wich Way"
      },
      {
        "city": "Medali",
        "store": "Every Wich Way"
      },
      {
        "city": "Montenevera",
        "store": "Every Wich Way"
      },
      {
        "city": "Zapapico",
        "store": "Every Wich Way"
      },
      {
        "city": "Levincia",
        "store": "Every Wich Way"
      },
      {
        "city": "Cascarafa",
        "store": "Every Wich Way"
      },
      {
        "city": "Mesagoza",
        "store": "Every Wich Way"
      }
    ]
  },
  {
    "meal": "Avocado Sandwich",
    "cost": "1200",
    "powers": [
      {
        "power": "EXP",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "ELECTRIC",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "DARK",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Alfornada",
        "store": "Every Wich Way"
      },
      {
        "city": "Porto Marinada",
        "store": "Every Wich Way"
      },
      {
        "city": "Medali",
        "store": "Every Wich Way"
      },
      {
        "city": "Montenevera",
        "store": "Every Wich Way"
      },
      {
        "city": "Zapapico",
        "store": "Every Wich Way"
      },
      {
        "city": "Levincia",
        "store": "Every Wich Way"
      },
      {
        "city": "Cascarafa",
        "store": "Every Wich Way"
      },
      {
        "city": "Mesagoza",
        "store": "Every Wich Way"
      }
    ]
  },
  {
    "meal": "Galette de la Maman",
    "cost": "3000",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "ELECTRIC",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "FIRE",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Alfornada",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Medali",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Levincia",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Cascarafa",
        "store": "Gastronomie En Famille"
      }
    ]
  },
  {
    "meal": "Compote du Fils",
    "cost": "2800",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "FLYING",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FAIRY",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Alfornada",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Medali",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Levincia",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Cascarafa",
        "store": "Gastronomie En Famille"
      }
    ]
  },
  {
    "meal": "Ratatouille du Grand-pére",
    "cost": "3600",
    "powers": [
      {
        "power": "ITEM",
        "type": "PSYCHIC",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "ICE",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "ROCK",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Alfornada",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Medali",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Levincia",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Cascarafa",
        "store": "Gastronomie En Famille"
      }
    ]
  },
  {
    "meal": "Quiche de la Grand-mére",
    "cost": "3400",
    "powers": [
      {
        "power": "CATCHING",
        "type": "ICE",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Alfornada",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Medali",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Levincia",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Cascarafa",
        "store": "Gastronomie En Famille"
      }
    ]
  },
  {
    "meal": "Pot-au-Feu de la Fille",
    "cost": "3800",
    "powers": [
      {
        "power": "TEENSY",
        "type": "STEEL",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "FLYING",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Alfornada",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Medali",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Levincia",
        "store": "Gastronomie En Famille"
      },
      {
        "city": "Cascarafa",
        "store": "Gastronomie En Famille"
      }
    ]
  },
  {
    "meal": "Spicy Potatoes",
    "cost": "1450",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "GRASS",
        "level": "1"
      }
    ],
    "shops": []
  },
  {
    "meal": "Alfajores",
    "cost": "950",
    "powers": [
      {
        "power": "RAID",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "DARK",
        "level": "1"
      }
    ],
    "shops": []
  },
  {
    "meal": "Lemon Soda",
    "cost": "900",
    "powers": [
      {
        "power": "TEENSY",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "WATER",
        "level": "1"
      }
    ],
    "shops": []
  },
  {
    "meal": "Coffee",
    "cost": "850",
    "powers": [
      {
        "power": "ITEM",
        "type": "WATER",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "GRASS",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "STEEL",
        "level": "1"
      }
    ],
    "shops": []
  },
  {
    "meal": "Quesadilla",
    "cost": "1600",
    "powers": [
      {
        "power": "RAID",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "PSYCHIC",
        "level": "1"
      }
    ],
    "shops": []
  },
  {
    "meal": "Churro",
    "cost": "850",
    "powers": [
      {
        "power": "EXP",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "GROUND",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "FIRE",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Smoochurro"
      },
      {
        "city": "Levincia",
        "store": "Smoochurro"
      }
    ]
  },
  {
    "meal": "Chocolate Churro",
    "cost": "950",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "NORMAL",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Smoochurro"
      },
      {
        "city": "Levincia",
        "store": "Smoochurro"
      }
    ]
  },
  {
    "meal": "Cinnamon Churro",
    "cost": "900",
    "powers": [
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "GRASS",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Smoochurro"
      },
      {
        "city": "Levincia",
        "store": "Smoochurro"
      }
    ]
  },
  {
    "meal": "Seafood Pinchos",
    "cost": "1500",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "WATER",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "GHOST",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Seafood Fresco"
      },
      {
        "city": "Levincia",
        "store": "Seafood Fresco"
      },
      {
        "city": "Mesagoza",
        "store": "Seafood Fresco"
      }
    ]
  },
  {
    "meal": "Arros con Leche",
    "cost": "950",
    "powers": [
      {
        "power": "ITEM",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "GRASS",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "GROUND",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Seafood Fresco"
      },
      {
        "city": "Levincia",
        "store": "Seafood Fresco"
      },
      {
        "city": "Mesagoza",
        "store": "Seafood Fresco"
      }
    ]
  },
  {
    "meal": "Ceviche",
    "cost": "1300",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "GROUND",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Seafood Fresco"
      },
      {
        "city": "Levincia",
        "store": "Seafood Fresco"
      },
      {
        "city": "Mesagoza",
        "store": "Seafood Fresco"
      }
    ]
  },
  {
    "meal": "Smoked Fillet with Herbs",
    "cost": "1400",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "FAIRY",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Seafood Fresco"
      },
      {
        "city": "Levincia",
        "store": "Seafood Fresco"
      },
      {
        "city": "Mesagoza",
        "store": "Seafood Fresco"
      }
    ]
  },
  {
    "meal": "Seafood Pasta",
    "cost": "1600",
    "powers": [
      {
        "power": "TEENSY",
        "type": "GRASS",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "FLYING",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Seafood Fresco"
      },
      {
        "city": "Levincia",
        "store": "Seafood Fresco"
      },
      {
        "city": "Mesagoza",
        "store": "Seafood Fresco"
      }
    ]
  },
  {
    "meal": "Mapo Tofu",
    "cost": "1700",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "FLYING",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "PSYCHIC",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Jade Palace Garden"
      },
      {
        "city": "Cascarafa",
        "store": "Jade Palace Garden"
      }
    ]
  },
  {
    "meal": "Annin Tofu",
    "cost": "900",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "ICE",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Jade Palace Garden"
      },
      {
        "city": "Cascarafa",
        "store": "Jade Palace Garden"
      }
    ]
  },
  {
    "meal": "Pickled Toedscool and Cucumber",
    "cost": "950",
    "powers": [
      {
        "power": "TEENSY",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "ROCK",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Jade Palace Garden"
      },
      {
        "city": "Cascarafa",
        "store": "Jade Palace Garden"
      }
    ]
  },
  {
    "meal": "Pepper Steak",
    "cost": "1700",
    "powers": [
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "GRASS",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Jade Palace Garden"
      },
      {
        "city": "Cascarafa",
        "store": "Jade Palace Garden"
      }
    ]
  },
  {
    "meal": "Ramen",
    "cost": "1600",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "GROUND",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "DARK",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Jade Palace Garden"
      },
      {
        "city": "Cascarafa",
        "store": "Jade Palace Garden"
      }
    ]
  },
  {
    "meal": "Paella de Paldea",
    "cost": "1800",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "WATER",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Levincia",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Cascarafa",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Mesagoza",
        "store": "Go-For-Broke Grill"
      }
    ]
  },
  {
    "meal": "Fruit Punch",
    "cost": "950",
    "powers": [
      {
        "power": "CATCHING",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "WATER",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Levincia",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Cascarafa",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Mesagoza",
        "store": "Go-For-Broke Grill"
      }
    ]
  },
  {
    "meal": "Escalivada",
    "cost": "1500",
    "powers": [
      {
        "power": "RAID",
        "type": "ICE",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "PSYCHIC",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Levincia",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Cascarafa",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Mesagoza",
        "store": "Go-For-Broke Grill"
      }
    ]
  },
  {
    "meal": "Potato Tortilla",
    "cost": "1600",
    "powers": [
      {
        "power": "ITEM",
        "type": "POISON",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "GRASS",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Levincia",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Cascarafa",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Mesagoza",
        "store": "Go-For-Broke Grill"
      }
    ]
  },
  {
    "meal": "Klawf al Ajillo",
    "cost": "1500",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "ELECTRIC",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "FAIRY",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Levincia",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Cascarafa",
        "store": "Go-For-Broke Grill"
      },
      {
        "city": "Mesagoza",
        "store": "Go-For-Broke Grill"
      }
    ]
  },
  {
    "meal": "Four-Piece Sushi (Flower Set)",
    "cost": "2000",
    "powers": [
      {
        "power": "CATCHING",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "BUG",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "DRAGON",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sushi High Roller"
      },
      {
        "city": "Levincia",
        "store": "Sushi High Roller"
      }
    ]
  },
  {
    "meal": "Four-Piece Sushi (Bird Set)",
    "cost": "2000",
    "powers": [
      {
        "power": "TEENSY",
        "type": "ELECTRIC",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GRASS",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sushi High Roller"
      },
      {
        "city": "Levincia",
        "store": "Sushi High Roller"
      }
    ]
  },
  {
    "meal": "Four-Piece Sushi (Wind Set)",
    "cost": "2000",
    "powers": [
      {
        "power": "ITEM",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "ICE",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "BUG",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sushi High Roller"
      },
      {
        "city": "Levincia",
        "store": "Sushi High Roller"
      }
    ]
  },
  {
    "meal": "Four-Piece Sushi (Moon Set)",
    "cost": "2000",
    "powers": [
      {
        "power": "EXP",
        "type": "FIGHTING",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GROUND",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sushi High Roller"
      },
      {
        "city": "Levincia",
        "store": "Sushi High Roller"
      }
    ]
  },
  {
    "meal": "Miso Soup",
    "cost": "900",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "FLYING",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "WATER",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "ICE",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sushi High Roller"
      },
      {
        "city": "Levincia",
        "store": "Sushi High Roller"
      }
    ]
  },
  {
    "meal": "Mustard Rice Ball",
    "cost": "750",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "ICE",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "ELECTRIC",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Treasure Eatery"
      }
    ]
  },
  {
    "meal": "Sweet Adzuki Bean Soup",
    "cost": "800",
    "powers": [
      {
        "power": "EXP",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "STEEL",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "POISON",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Treasure Eatery"
      }
    ]
  },
  {
    "meal": "Homemade Umeboshi",
    "cost": "500",
    "powers": [
      {
        "power": "TEENSY",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Treasure Eatery"
      }
    ]
  },
  {
    "meal": "Bitter Melon Stir-Fry",
    "cost": "950",
    "powers": [
      {
        "power": "ITEM",
        "type": "GROUND",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "ROCK",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Treasure Eatery"
      }
    ]
  },
  {
    "meal": "Soba Noodle Soup",
    "cost": "920",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIRE",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GRASS",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Treasure Eatery"
      }
    ]
  },
  {
    "meal": "Exlusive Four-Piece Sushi (Frost Set)",
    "cost": "4000",
    "powers": [
      {
        "power": "RAID",
        "type": "WATER",
        "level": "2"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "STEEL",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Montenevera",
        "store": "Sushi High Roller 2"
      }
    ]
  },
  {
    "meal": "Exlusive Four-Piece Sushi (Graupel Set)",
    "cost": "4000",
    "powers": [
      {
        "power": "EXP",
        "type": "BUG",
        "level": "2"
      },
      {
        "power": "TEENSY",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "ELECTRIC",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Montenevera",
        "store": "Sushi High Roller 2"
      }
    ]
  },
  {
    "meal": "Exlusive Four-Piece Sushi (Hail Set)",
    "cost": "4000",
    "powers": [
      {
        "power": "ITEM",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "ELECTRIC",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Montenevera",
        "store": "Sushi High Roller 2"
      }
    ]
  },
  {
    "meal": "Exlusive Four-Piece Sushi (Sleet Set)",
    "cost": "4000",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "STEEL",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "POISON",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "ROCK",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Montenevera",
        "store": "Sushi High Roller 2"
      }
    ]
  },
  {
    "meal": "Consomme",
    "cost": "3500",
    "powers": [
      {
        "power": "CATCHING",
        "type": "GHOST",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "WATER",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FIRE",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Montenevera",
        "store": "Sushi High Roller 2"
      }
    ]
  },
  {
    "meal": "Pinchitos",
    "cost": "1200",
    "powers": [
      {
        "power": "ITEM",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "DRAGON",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "ROCK",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Montenevera",
        "store": "Chic Kebab"
      },
      {
        "city": "Levincia",
        "store": "Chic Kebab"
      },
      {
        "city": "Cascarafa",
        "store": "Chic Kebab"
      },
      {
        "city": "Mesagoza",
        "store": "Chic Kebab"
      }
    ]
  },
  {
    "meal": "Grilled Rice Balls",
    "cost": "950",
    "powers": [
      {
        "power": "ENCOUNTER",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "ELECTRIC",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Sugar-N-Ice 2"
      }
    ]
  },
  {
    "meal": "Strawberry Chocolate Crepe",
    "cost": "950",
    "powers": [
      {
        "power": "RAID",
        "type": "WATER",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Kanto Crepes"
      },
      {
        "city": "Artazon",
        "store": "Kanto Crepes"
      },
      {
        "city": "Levincia",
        "store": "Kanto Crepes"
      },
      {
        "city": "Mesagoza",
        "store": "Kanto Crepes"
      }
    ]
  },
  {
    "meal": "Strawberry Whipped Cream Crepe",
    "cost": "950",
    "powers": [
      {
        "power": "EXP",
        "type": "GROUND",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "ICE",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "ELECTRIC",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Kanto Crepes"
      },
      {
        "city": "Artazon",
        "store": "Kanto Crepes"
      },
      {
        "city": "Levincia",
        "store": "Kanto Crepes"
      },
      {
        "city": "Mesagoza",
        "store": "Kanto Crepes"
      }
    ]
  },
  {
    "meal": "Chocolate Banana Crepe",
    "cost": "900",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "WATER",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "STEEL",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Medali",
        "store": "Kanto Crepes"
      },
      {
        "city": "Artazon",
        "store": "Kanto Crepes"
      },
      {
        "city": "Levincia",
        "store": "Kanto Crepes"
      },
      {
        "city": "Mesagoza",
        "store": "Kanto Crepes"
      }
    ]
  },
  {
    "meal": "Dry Curry",
    "cost": "1800",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "GROUND",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "WATER",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Artazon",
        "store": "Barato's"
      },
      {
        "city": "Levincia",
        "store": "Barato's"
      },
      {
        "city": "Mesagoza",
        "store": "Barato's"
      }
    ]
  },
  {
    "meal": "Chocolate-Vanilla Fruit Parfait",
    "cost": "1400",
    "powers": [
      {
        "power": "CATCHING",
        "type": "GROUND",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "DARK",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "POISON",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Artazon",
        "store": "Barato's"
      },
      {
        "city": "Levincia",
        "store": "Barato's"
      },
      {
        "city": "Mesagoza",
        "store": "Barato's"
      }
    ]
  },
  {
    "meal": "Lemon Gelato",
    "cost": "900",
    "powers": [
      {
        "power": "ITEM",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "ROCK",
        "level": "1"
      },
      {
        "power": "EXP",
        "type": "NORMAL",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Artazon",
        "store": "Barato's"
      },
      {
        "city": "Levincia",
        "store": "Barato's"
      },
      {
        "city": "Mesagoza",
        "store": "Barato's"
      }
    ]
  },
  {
    "meal": "Caesar Salad",
    "cost": "1300",
    "powers": [
      {
        "power": "EXP",
        "type": "FLYING",
        "level": "1"
      },
      {
        "power": "TEENSY",
        "type": "NORMAL",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "FIRE",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Artazon",
        "store": "Barato's"
      },
      {
        "city": "Levincia",
        "store": "Barato's"
      },
      {
        "city": "Mesagoza",
        "store": "Barato's"
      }
    ]
  },
  {
    "meal": "Salisbury Steak with Fried Fixings",
    "cost": "1900",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      },
      {
        "power": "ENCOUNTER",
        "type": "FLYING",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "FAIRY",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Artazon",
        "store": "Barato's"
      },
      {
        "city": "Levincia",
        "store": "Barato's"
      },
      {
        "city": "Mesagoza",
        "store": "Barato's"
      }
    ]
  },
  {
    "meal": "Dandan Noodles",
    "cost": "3600",
    "powers": [
      {
        "power": "ITEM",
        "type": "FIRE",
        "level": "2"
      },
      {
        "power": "HUMUNGO",
        "type": "GRASS",
        "level": "1"
      },
      {
        "power": "EGG",
        "type": "",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Cascarafa",
        "store": "Kofu Lounge"
      }
    ]
  },
  {
    "meal": "Tofu Pudding",
    "cost": "3000",
    "powers": [
      {
        "power": "EXP",
        "type": "POISON",
        "level": "2"
      },
      {
        "power": "ENCOUNTER",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "RAID",
        "type": "ICE",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Cascarafa",
        "store": "Kofu Lounge"
      }
    ]
  },
  {
    "meal": "Hot and Sour Soup",
    "cost": "3200",
    "powers": [
      {
        "power": "EGG",
        "type": "",
        "level": "2"
      },
      {
        "power": "ITEM",
        "type": "FAIRY",
        "level": "1"
      },
      {
        "power": "HUMUNGO",
        "type": "FIRE",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Cascarafa",
        "store": "Kofu Lounge"
      }
    ]
  },
  {
    "meal": "Oolong Tea",
    "cost": "2800",
    "powers": [
      {
        "power": "TEENSY",
        "type": "DRAGON",
        "level": "2"
      },
      {
        "power": "CATCHING",
        "type": "GHOST",
        "level": "1"
      },
      {
        "power": "ITEM",
        "type": "ICE",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Cascarafa",
        "store": "Kofu Lounge"
      }
    ]
  },
  {
    "meal": "House Special Hot Pot",
    "cost": "3800",
    "powers": [
      {
        "power": "HUMUNGO",
        "type": "ROCK",
        "level": "2"
      },
      {
        "power": "EXP",
        "type": "PSYCHIC",
        "level": "1"
      },
      {
        "power": "CATCHING",
        "type": "STEEL",
        "level": "1"
      }
    ],
    "shops": [
      {
        "city": "Cascarafa",
        "store": "Kofu Lounge"
      }
    ]
  }
]