<template>
  <div>
    <h3 class="meal-header">{{ meal.meal }} {{ isSandwich ? `(#${meal.number})` : '' }}</h3>
    <div class="meal-data">
      <div>Powers</div>
      <div class="left-wrapper">
        <div class="table">
          <div class="table-row" v-for="(power, idx) in meal.powers" :key="`power-${idx}`">
            <div class="table-cell">
              {{ powerText(power) }}
            </div>
            <div class="table-cell">
              Lv. {{ power.level }}
            </div>
          </div>
        </div>
      </div>
      <div>Locations</div>
      <div class="left-wrapper" v-if="!isSandwich">
        <div class="table">
          <div class="table-row" v-for="(location, idx) in meal.shops" :key="`power-${idx}`">
            <div class="table-cell">
              {{ location.city }}
            </div>
            <div class="table-cell">
              {{ location.store }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="isSandwich">
        <div class="left-wrapper">
          {{ GetText(meal.location) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetText } from '../store/TextHelper'
export default {
  props: {
    meal: {
      type: Object
    },
    isSandwich: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    powerText: function (power) {
      if (power.power === 'EGG') {
        return 'Egg Power'
      }
      return `${GetText(power.power + '_TEXT')}: ${GetText(power.type)}`
    },
    GetText: GetText
  }
}
</script>

<style>
.meal-header {
  font-size: 1.3em;
  color: gray;
}

.meal-data {
  margin-left: 15px;
}

.table-cell {
  padding-right: 10px;
}
</style>
