<template>
  <div class="content">
    <h1>{{ GetText(itemId) }}</h1>
    <h2>Locations</h2>
    <ul>
      <li v-for="store in stores" :key="store.id">
        <div>
          {{ GetText(store.city)}}{{ store.location ? ', ' : ''}} {{ GetText(store.location)}}
        </div>
        <div>
          {{ GetText(store.building)}}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { GetText } from '../store/TextHelper'
import { GetStoresWithItem } from '../store/StoreHelper'
export default {
  data: function () {
    const route = useRoute()
    const itemId = route.params.itemId.toUpperCase()
    return {
      itemId,
      stores: GetStoresWithItem(itemId)
    }
  },
  methods: {
    GetText,
    GetStoresWithItem
  }
}
</script>
