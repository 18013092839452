export function GroupByField (array, field) {
  const result = {}
  array.forEach(arr => {
    const f = arr[field]
    if (!result[f]) {
      result[f] = []
    }
    result[f].push(arr)
  })
  return result
}
