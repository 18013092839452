export default [
  {
    "id": "ABILITYCAPSULE",
    "english": "Ability Capsule"
  },
  {
    "id": "ABILITYSHIELD",
    "english": "Ability Shield"
  },
  {
    "id": "ABSORBBULB",
    "english": "Absorb Bulb"
  },
  {
    "id": "ADMANTMINT",
    "english": "Adamant Mint"
  },
  {
    "id": "ADRENALINEORB",
    "english": "Adrenaline Orb"
  },
  {
    "id": "AIRBALLOON",
    "english": "Air Balloon"
  },
  {
    "id": "ALFORNADA",
    "english": "Alfornada"
  },
  {
    "id": "APPLE",
    "english": "Apple"
  },
  {
    "id": "AQUIESTASUPERMARKET",
    "english": "Aquiesta Supermarket"
  },
  {
    "id": "ARTISANBAKERY",
    "english": "Artisan Bakery"
  },
  {
    "id": "ASSAULTVEST",
    "english": "Assault Vest"
  },
  {
    "id": "ATTACK",
    "english": "Attack"
  },
  {
    "id": "AVOCADO",
    "english": "Avocado"
  },
  {
    "id": "AVOCADOSANDWICH_44",
    "english": "Avocado Sandwich (#44)"
  },
  {
    "id": "AZURILL",
    "english": "Azurill"
  },
  {
    "id": "BACON",
    "english": "Bacon"
  },
  {
    "id": "BANANA",
    "english": "Banana"
  },
  {
    "id": "BASIL",
    "english": "Basil"
  },
  {
    "id": "BATTLE",
    "english": "Battle Items"
  },
  {
    "id": "BIGROOT",
    "english": "Big Root"
  },
  {
    "id": "BINDINGBAND",
    "english": "Binding Band"
  },
  {
    "id": "BITTER_HERBA",
    "english": "Sandwich Shop with Bitter Herba Mystica"
  },
  {
    "id": "BLACKBELT",
    "english": "Blackbelt"
  },
  {
    "id": "BLACKGLASSES",
    "english": "Black Glasses"
  },
  {
    "id": "BLACKSLUDGE",
    "english": "Black Sludge"
  },
  {
    "id": "BLUNDERPOLICY",
    "english": "Blunder Policy"
  },
  {
    "id": "BOLDMINT",
    "english": "Bold Mint"
  },
  {
    "id": "BOTTLECAP",
    "english": "Bottle cap"
  },
  {
    "id": "BRAVEMINT",
    "english": "Brave Mint"
  },
  {
    "id": "BREEDING",
    "english": "Breeding"
  },
  {
    "id": "BRIGHTPOWDER",
    "english": "Bright Powder"
  },
  {
    "id": "BUG",
    "english": "Bug"
  },
  {
    "id": "BUTTER",
    "english": "Butter"
  },
  {
    "id": "CALCIUM",
    "english": "Calcium"
  },
  {
    "id": "CALMMINT",
    "english": "Calm Mint"
  },
  {
    "id": "CARBOS",
    "english": "Carbos"
  },
  {
    "id": "CAREFULMINT",
    "english": "Careful Mint"
  },
  {
    "id": "CASCARAFFA",
    "english": "Cascaraffa"
  },
  {
    "id": "CATCHING_TEXT",
    "english": "Catching Power"
  },
  {
    "id": "CELLBATTERY",
    "english": "Cell Battery"
  },
  {
    "id": "CHANSEYSUPPLY",
    "english": "Chansey Supply"
  },
  {
    "id": "CHARCOAL",
    "english": "Charcoal"
  },
  {
    "id": "CHEESE",
    "english": "Cheese"
  },
  {
    "id": "CHERRYTOMATOES",
    "english": "Cherry Tomatoes"
  },
  {
    "id": "CHILISAUCE",
    "english": "Chili Sauce"
  },
  {
    "id": "CHOICEBAND",
    "english": "Choice Band"
  },
  {
    "id": "CHOICESCARF",
    "english": "Choice Scarf"
  },
  {
    "id": "CHOICESPECS",
    "english": "Choice Specs"
  },
  {
    "id": "CHORIZO",
    "english": "Chorizo"
  },
  {
    "id": "CLEARAMULET",
    "english": "Clear Amulet"
  },
  {
    "id": "CLEVERFEATHER",
    "english": "Clever Feather"
  },
  {
    "id": "CORTONDO",
    "english": "Cortondo"
  },
  {
    "id": "COVERTCLOAK",
    "english": "Covert Cloak"
  },
  {
    "id": "CREAMCHEESE",
    "english": "Cream Cheese"
  },
  {
    "id": "CUCUMBER",
    "english": "Cucumber"
  },
  {
    "id": "CURRYPOWDER",
    "english": "Curry Powder"
  },
  {
    "id": "DAMPROCK",
    "english": "Damp Rock"
  },
  {
    "id": "DARK",
    "english": "Dark"
  },
  {
    "id": "DEFENSE",
    "english": "Defense"
  },
  {
    "id": "DELIBIRDPRESENTS",
    "english": "Delibird Presents"
  },
  {
    "id": "DELICIOSO",
    "english": "Delicioso"
  },
  {
    "id": "DESTINYKNOT",
    "english": "Destiny Knot"
  },
  {
    "id": "DIREHIT",
    "english": "Dire Hit"
  },
  {
    "id": "DRAGON",
    "english": "Dragon"
  },
  {
    "id": "DRAGONFANG",
    "english": "Dragon Fang"
  },
  {
    "id": "EAST",
    "english": "East"
  },
  {
    "id": "EGG",
    "english": "Egg"
  },
  {
    "id": "EGG_SANDWICH_60",
    "english": "Egg Sandwich (#60)"
  },
  {
    "id": "EJECTBUTTON",
    "english": "Eject Button"
  },
  {
    "id": "EJECTPACK",
    "english": "Eject Pack"
  },
  {
    "id": "ELECTRIC",
    "english": "Electric"
  },
  {
    "id": "ELECTRICSEED",
    "english": "Electric Seed"
  },
  {
    "id": "ENCOUNTER_TEXT",
    "english": "Encounter Power"
  },
  {
    "id": "ENERGYPOWDER",
    "english": "Energy Powder"
  },
  {
    "id": "ENERGYROOT",
    "english": "Energy Root"
  },
  {
    "id": "EVERSTONE",
    "english": "Everstone"
  },
  {
    "id": "EVIOLITE",
    "english": "Eviolite"
  },
  {
    "id": "EVOLUTION",
    "english": "Evolution Items"
  },
  {
    "id": "EXPERTBELT",
    "english": "Expert Belt"
  },
  {
    "id": "EXP_TEXT",
    "english": "Exp. Point Power"
  },
  {
    "id": "FAIRY",
    "english": "Fairy"
  },
  {
    "id": "FARWEST",
    "english": "Far West"
  },
  {
    "id": "FIGHTING",
    "english": "Fighting"
  },
  {
    "id": "FIRE",
    "english": "Fire"
  },
  {
    "id": "FIRESTONE",
    "english": "Fire Stone"
  },
  {
    "id": "FLAMEORB",
    "english": "Flame Orb"
  },
  {
    "id": "FLETCHLING",
    "english": "Fletchling"
  },
  {
    "id": "FLYING",
    "english": "Flying"
  },
  {
    "id": "FOCUSBAND",
    "english": "Focus Band"
  },
  {
    "id": "FOCUSSASH",
    "english": "Focus Sash"
  },
  {
    "id": "FRIEDFILLET",
    "english": "Fried Fillet"
  },
  {
    "id": "GENERAL",
    "english": "General Items"
  },
  {
    "id": "GENIUSFEATHER",
    "english": "Genius Feather"
  },
  {
    "id": "GENTLEMINT",
    "english": "Gentle Mint"
  },
  {
    "id": "GHOST",
    "english": "Ghost"
  },
  {
    "id": "GRASS",
    "english": "Grass"
  },
  {
    "id": "GRASSYSEED",
    "english": "Grassy Seed"
  },
  {
    "id": "GREENBELLPEPPER",
    "english": "Green Bell Pepper"
  },
  {
    "id": "GREPA_BERRY",
    "english": "Grepa Berry"
  },
  {
    "id": "GRIPCLAW",
    "english": "Grip Claw"
  },
  {
    "id": "GROUND",
    "english": "Ground"
  },
  {
    "id": "GUARDSPEC",
    "english": "Guard Spec"
  },
  {
    "id": "HAM",
    "english": "Ham"
  },
  {
    "id": "HAMBURGER",
    "english": "Hamburger"
  },
  {
    "id": "HAMSANDWICH_80",
    "english": "Ham Sandwich (#80)"
  },
  {
    "id": "HARDSTONE",
    "english": "Hard Stone"
  },
  {
    "id": "HASTYMINT",
    "english": "Hasty Mint"
  },
  {
    "id": "HEALPOWDER",
    "english": "Heal Powder"
  },
  {
    "id": "HEALTHFEATHER",
    "english": "Health Feather"
  },
  {
    "id": "HEATROCK",
    "english": "Heat Rock"
  },
  {
    "id": "HEAVYDUTYBOOTS",
    "english": "Heavy Duty Boots"
  },
  {
    "id": "HERBEDSAUSAGE",
    "english": "Herbed Sausage"
  },
  {
    "id": "HONDEW_BERRY",
    "english": "Hondew Berry"
  },
  {
    "id": "HORSERADISH",
    "english": "Horseradish"
  },
  {
    "id": "HP",
    "english": "HP"
  },
  {
    "id": "HPUP",
    "english": "HP Up"
  },
  {
    "id": "HUMUNGO_TEXT",
    "english": "Humungo Power"
  },
  {
    "id": "ICE",
    "english": "Ice"
  },
  {
    "id": "ICYROCK",
    "english": "Icy Rock"
  },
  {
    "id": "IMPISHMINT",
    "english": "Impish Mint"
  },
  {
    "id": "INGREDIENT",
    "english": "Ingredients"
  },
  {
    "id": "IRON",
    "english": "Iron"
  },
  {
    "id": "IRONBALL",
    "english": "Iron ball"
  },
  {
    "id": "ITEM_TEXT",
    "english": "Item Drop Power"
  },
  {
    "id": "JALAPENO",
    "english": "Jalapeno"
  },
  {
    "id": "JAM",
    "english": "Jam"
  },
  {
    "id": "JAMBONBUERRE_1",
    "english": "Jambon-Buerre (#1)"
  },
  {
    "id": "JOLLYMINT",
    "english": "Jolly Mint"
  },
  {
    "id": "KELPSY_BERRY",
    "english": "Kelpsy Berry"
  },
  {
    "id": "KETCHUP",
    "english": "Ketchup"
  },
  {
    "id": "KINGSROCK",
    "english": "Kings Rock"
  },
  {
    "id": "KIWI",
    "english": "Kiwi"
  },
  {
    "id": "KLAWFSTICK",
    "english": "Klawf Stick"
  },
  {
    "id": "LAGGINTAIL",
    "english": "Lagging Tail"
  },
  {
    "id": "LAXMINT",
    "english": "Lax Mint"
  },
  {
    "id": "LEAFSTONE",
    "english": "Leaf Stone"
  },
  {
    "id": "LECHONK",
    "english": "Lechonk"
  },
  {
    "id": "LEFTOVERS",
    "english": "Leftovers"
  },
  {
    "id": "LETTUCE",
    "english": "Lettuce"
  },
  {
    "id": "LEVINCIA",
    "english": "Levincia"
  },
  {
    "id": "LIFEORB",
    "english": "Life Orb"
  },
  {
    "id": "LIGHTCLAY",
    "english": "Light Clay"
  },
  {
    "id": "LOADEDDICE",
    "english": "Loaded Dice"
  },
  {
    "id": "LONELYMINT",
    "english": "Lonely Mint"
  },
  {
    "id": "LOWER",
    "english": "Lower Level"
  },
  {
    "id": "LUMINOUSMOSS",
    "english": "Luminous Moss"
  },
  {
    "id": "MAGNET",
    "english": "Magnet "
  },
  {
    "id": "MARMALADE",
    "english": "Marmalade"
  },
  {
    "id": "MAYONNAISE",
    "english": "Mayonnaise"
  },
  {
    "id": "MENTALHERB",
    "english": "Mental Herb"
  },
  {
    "id": "MESAGOZA",
    "english": "Mesagoza"
  },
  {
    "id": "METALCOAT",
    "english": "Metal Coat"
  },
  {
    "id": "METRONOME",
    "english": "Metronome"
  },
  {
    "id": "MIDDLE",
    "english": "Middle Level"
  },
  {
    "id": "MILDMINT",
    "english": "Mild Mint"
  },
  {
    "id": "MIRACLESEED",
    "english": "Miracle Seed"
  },
  {
    "id": "MIRRORHERB",
    "english": "Mirror Herb"
  },
  {
    "id": "MISTYSEED",
    "english": "Misty Seed"
  },
  {
    "id": "MODESTMINT",
    "english": "Modest Mint"
  },
  {
    "id": "MONTENEVERA",
    "english": "Montenevera"
  },
  {
    "id": "MOTHER",
    "english": "Mother at home"
  },
  {
    "id": "MOTHER_BITTER",
    "english": "Mother at home with Bitter Herba Mystica"
  },
  {
    "id": "MOTHER_SALTY",
    "english": "Mother at home with Salty Herba Mystica"
  },
  {
    "id": "MOTHER_SOUR",
    "english": "Mother at home with Sour Herba Mystica"
  },
  {
    "id": "MOTHER_SPICY",
    "english": "Mother at home with Spicy Herba Mystica"
  },
  {
    "id": "MOTHER_SWEET",
    "english": "Mother at home with Sweet Herba Mystica"
  },
  {
    "id": "MUSCLEBAND",
    "english": "Muscle Band"
  },
  {
    "id": "MUSCLEFEATHER",
    "english": "Muscle Feather"
  },
  {
    "id": "MUSTARD",
    "english": "Mustard"
  },
  {
    "id": "MYSTICWATER",
    "english": "Mystic Water"
  },
  {
    "id": "NAIVEMINT",
    "english": "Naive Mint"
  },
  {
    "id": "NAUGHTYMINT",
    "english": "Naughty Mint"
  },
  {
    "id": "NEVERMELTICE",
    "english": "Nevermelt Ice"
  },
  {
    "id": "NOODLES",
    "english": "Noodles"
  },
  {
    "id": "NORMAL",
    "english": "Normal"
  },
  {
    "id": "NORMALGEM",
    "english": "Normal Gem"
  },
  {
    "id": "NORTH",
    "english": "North"
  },
  {
    "id": "OLIVEOIL",
    "english": "Olive Oil"
  },
  {
    "id": "ONION",
    "english": "Onion"
  },
  {
    "id": "PALDEANWOOPER",
    "english": "Paldean Wooper"
  },
  {
    "id": "PEANUTBUTTER",
    "english": "Peanut Butter"
  },
  {
    "id": "PEPPER",
    "english": "Pepper "
  },
  {
    "id": "PICKLE",
    "english": "Pickle "
  },
  {
    "id": "PINEAPPLE",
    "english": "Pineapple"
  },
  {
    "id": "PINKNECTAR",
    "english": "Pink Nectar"
  },
  {
    "id": "POISON",
    "english": "Poison"
  },
  {
    "id": "POISONBARB",
    "english": "Poison Barb"
  },
  {
    "id": "POMEG_BERRY",
    "english": "Pomeg Berry"
  },
  {
    "id": "POTATOSALAD",
    "english": "Potato Salad"
  },
  {
    "id": "POTATOTORTILLA",
    "english": "Potato Tortilla"
  },
  {
    "id": "POWERANKLET",
    "english": "Power Anklet"
  },
  {
    "id": "POWERBAND",
    "english": "Power Band"
  },
  {
    "id": "POWERBELT",
    "english": "Power Belt"
  },
  {
    "id": "POWERBRACER",
    "english": "Power Bracer"
  },
  {
    "id": "POWERHERB",
    "english": "Power Herb"
  },
  {
    "id": "POWERLENS",
    "english": "Power Lens"
  },
  {
    "id": "POWERWEIGHT",
    "english": "Power Weight"
  },
  {
    "id": "PPUP",
    "english": "PP Up"
  },
  {
    "id": "PROSCIUTTO",
    "english": "Prosciutto"
  },
  {
    "id": "PROTECTIVEPADS",
    "english": "Protective Pads"
  },
  {
    "id": "PROTEIN",
    "english": "Protein"
  },
  {
    "id": "PSYCHIC",
    "english": "Psychic"
  },
  {
    "id": "PSYCHICSEED",
    "english": "Psychic Seed"
  },
  {
    "id": "PSYDUCK",
    "english": "Psyduck"
  },
  {
    "id": "PUNCHINGGLOVE",
    "english": "Punching Glove"
  },
  {
    "id": "PURPLENECTAR",
    "english": "Purple Nectar"
  },
  {
    "id": "QUALOT_BERRY",
    "english": "Qualot Berry"
  },
  {
    "id": "QUICKCLAW",
    "english": "Quick Claw"
  },
  {
    "id": "QUIETMINT",
    "english": "Quiet Mint"
  },
  {
    "id": "RAID_TEXT",
    "english": "Raid Power"
  },
  {
    "id": "RASHMINT",
    "english": "Rash Mint"
  },
  {
    "id": "RAZORCLAW",
    "english": "Razor Claw"
  },
  {
    "id": "REDBELLPEPPER",
    "english": "Red Bell Pepper"
  },
  {
    "id": "REDCARD",
    "english": "Red Card"
  },
  {
    "id": "REDNECTAR",
    "english": "Red Nectar"
  },
  {
    "id": "REDONION",
    "english": "Red Onion"
  },
  {
    "id": "RELAXEDMINT",
    "english": "Relaxed Mint"
  },
  {
    "id": "RESISTFEATHER",
    "english": "Resist Feather"
  },
  {
    "id": "REVIVALHERB",
    "english": "Revival Herb"
  },
  {
    "id": "RICE",
    "english": "Rice"
  },
  {
    "id": "RINGTARGET",
    "english": "Ring Target"
  },
  {
    "id": "ROCK",
    "english": "Rock"
  },
  {
    "id": "ROCKYHELMET",
    "english": "Rocky Helmet"
  },
  {
    "id": "ROOKIDEE",
    "english": "Rookidee"
  },
  {
    "id": "ROOMSERVICE",
    "english": "Room Service"
  },
  {
    "id": "SAFETYGOGGLES",
    "english": "Safety Goggles"
  },
  {
    "id": "SALT",
    "english": "Salt"
  },
  {
    "id": "SALTY_HERBA",
    "english": "Sandwich Shop with Salty Herba Mystica"
  },
  {
    "id": "SANDWICH_SHOP",
    "english": "Sandwich Shop"
  },
  {
    "id": "SASSYMINT",
    "english": "Sassy Mint"
  },
  {
    "id": "SCATTERBUG",
    "english": "Scatterbug"
  },
  {
    "id": "SCOPELENS",
    "english": "Scope Lens"
  },
  {
    "id": "SERIOUSMINT",
    "english": "Serious Mint"
  },
  {
    "id": "SHARPBEAK",
    "english": "Sharp Beak"
  },
  {
    "id": "SHEDSHELL",
    "english": "Shed Shell"
  },
  {
    "id": "SHELLBELL",
    "english": "Shell Bell"
  },
  {
    "id": "SHINX",
    "english": "Shinx"
  },
  {
    "id": "SILKSCARF",
    "english": "Silk Scarf"
  },
  {
    "id": "SILVERPOWDER",
    "english": "Silver Powder"
  },
  {
    "id": "SMOKEBALL",
    "english": "Smoke Ball"
  },
  {
    "id": "SMOKEDFILLET",
    "english": "Smoked Fillet"
  },
  {
    "id": "SMOKYSANDWICH_92",
    "english": "Smoky Sandwich (#92)"
  },
  {
    "id": "SMOOTHROCK",
    "english": "Smooth Rock"
  },
  {
    "id": "SNOWBALL",
    "english": "Snowball"
  },
  {
    "id": "SOFTSAND",
    "english": "Soft Sand"
  },
  {
    "id": "SOOTHEBELL",
    "english": "Soothe Bell"
  },
  {
    "id": "SOUR_HERBA",
    "english": "Sandwich Shop with Sour Herba Mystica"
  },
  {
    "id": "SOUTH",
    "english": "South"
  },
  {
    "id": "SOUTHPROVINCE_AREA1",
    "english": "South Province (Area One)"
  },
  {
    "id": "SOUTHPROVINCE_AREA2",
    "english": "South Province (Area Two)"
  },
  {
    "id": "SOUTHPROVINCE_AREA3",
    "english": "South Province (Area Three)"
  },
  {
    "id": "SOUTHPROVINCE_AREA4",
    "english": "South Province (Area Four)"
  },
  {
    "id": "SOUTHPROVINCE_AREA5",
    "english": "South Province (Area Five)"
  },
  {
    "id": "SOUTHWEST",
    "english": "South West"
  },
  {
    "id": "SPECIALATTACK",
    "english": "Special Attack"
  },
  {
    "id": "SPECIALDEFENSE",
    "english": "Special Defense"
  },
  {
    "id": "SPEED",
    "english": "Speed"
  },
  {
    "id": "SPELLTAG",
    "english": "Spell Tag"
  },
  {
    "id": "SPICY_HERBA",
    "english": "Sandwich Shop with Spicy Herba Mystica"
  },
  {
    "id": "SPOINK",
    "english": "Spoink"
  },
  {
    "id": "START",
    "english": "Start of Game"
  },
  {
    "id": "STEEL",
    "english": "Steel"
  },
  {
    "id": "STICKYBARB",
    "english": "Sticky Barb"
  },
  {
    "id": "STRAWBERRY",
    "english": "Strawberry"
  },
  {
    "id": "SURECANS",
    "english": "Sure Cans"
  },
  {
    "id": "SURECANSWEST",
    "english": "Sure Cans"
  },
  {
    "id": "SUSHI_OFICIANADO_CASSEROYA",
    "english": "Sushi Oficianado in Casseroya Lake"
  },
  {
    "id": "SWEETAPPLE",
    "english": "Sweet Apple"
  },
  {
    "id": "SWEET_HERBA",
    "english": "Sandwich Shop with Sweet Herba Mystica"
  },
  {
    "id": "SWIFTFEATHER",
    "english": "Swift Feather"
  },
  {
    "id": "TAMATO_BERRY",
    "english": "Tamato Berry"
  },
  {
    "id": "TAROUNTULA",
    "english": "Tarountula"
  },
  {
    "id": "TARTAPPLE",
    "english": "Tart Apple"
  },
  {
    "id": "TEENSY_TEXT",
    "english": "Teensy Power"
  },
  {
    "id": "TERRAINEXTENDER",
    "english": "Terrain Extender"
  },
  {
    "id": "THROATSPRAY",
    "english": "Throat Spray"
  },
  {
    "id": "THUNDERSTONE",
    "english": "Thunder Stone"
  },
  {
    "id": "TIMIDMINT",
    "english": "Timid Mint"
  },
  {
    "id": "TITLE_TEXT",
    "english": "Title Power"
  },
  {
    "id": "TOFU",
    "english": "Tofu"
  },
  {
    "id": "TOMATO",
    "english": "Tomato "
  },
  {
    "id": "TOXICORB",
    "english": "Toxic Orb"
  },
  {
    "id": "TWISTEDSPOON",
    "english": "Twisted Spoon"
  },
  {
    "id": "UTILITYUMBRELLA",
    "english": "Utility Umbrella"
  },
  {
    "id": "VINEGAR",
    "english": "Vinegar"
  },
  {
    "id": "WASABI",
    "english": "Wasabi "
  },
  {
    "id": "WATER",
    "english": "Water"
  },
  {
    "id": "WATERCRESS",
    "english": "Watercress"
  },
  {
    "id": "WATERSTONE",
    "english": "Waters Tone"
  },
  {
    "id": "WEAKNESSPOLICY",
    "english": "Weakness Policy"
  },
  {
    "id": "WEST",
    "english": "West"
  },
  {
    "id": "WHIPPEDCREAM",
    "english": "Whipped Cream"
  },
  {
    "id": "WHITEHERB",
    "english": "White Herb"
  },
  {
    "id": "WIDELENS",
    "english": "Wide Lens"
  },
  {
    "id": "WISEGLASSES",
    "english": "Wise Glasses"
  },
  {
    "id": "XACCURACY",
    "english": "X Accuracy"
  },
  {
    "id": "XATTACK",
    "english": "X Attack"
  },
  {
    "id": "XDEFENSE",
    "english": "X Defense"
  },
  {
    "id": "XSPATK",
    "english": "X Sp. Atk"
  },
  {
    "id": "XSPDEF",
    "english": "X Sp. Def"
  },
  {
    "id": "XSPEED",
    "english": "X Speed"
  },
  {
    "id": "YELLOWBELLPEPPER",
    "english": "Yellow Bellpepper"
  },
  {
    "id": "YELLOWNECTAR",
    "english": "Yellow Nectar"
  },
  {
    "id": "YOGURT",
    "english": "Yogurt "
  },
  {
    "id": "YUNGOOS",
    "english": "Yungoos"
  },
  {
    "id": "ZESTYSANDWICH_56",
    "english": "Zesty Sandwich (#56)"
  },
  {
    "id": "ZINC",
    "english": "Zinc"
  },
  {
    "id": "ZOOMLENS",
    "english": "Zoom Lens"
  }
]