<template>
  <div class="content">
    <o-tabs
      :open="isOpen == index"
      @open="isOpen = index"
    >
      <o-tab-item
        v-for="(category, index) of categories"
        :key="index"
        :label="GetText(category)"
      >
        <div class="card-content132">
          <div class="content123">
            <o-input
              placeholder="Filter"
              v-model="filters[category]"
              icon-right="close"
              icon-right-clickable
              @icon-right-click="ClearIconClick(category)"
            >
            </o-input>
            <item-locations :items="GetFilteredItems(category)" />
          </div>
        </div>
      </o-tab-item>
    </o-tabs>
  </div>
</template>

<script>
import Items from '../store/items.mjs'
import { ref } from 'vue'
import { GetText } from '../store/TextHelper'
import ItemLocations from '../components/ItemLocations.vue'
export default {
  components: {
    ItemLocations
  },
  data: function () {
    const categories = [
      'BATTLE',
      'EVOLUTION',
      'GENERAL',
      'INGREDIENT',
      'BREEDING'
    ]
    return {
      index: -1,
      items: Items,
      isOpen: ref(0),
      categories,
      filters: categories.reduce((p, n) => {
        p[n] = ''
        return p
      }, {})
    }
  },
  methods: {
    GetText,
    GetItems: function (category) {
      return this.items.filter(item => item.categories.indexOf(category) !== -1)
    },
    GetFilteredItems: function (category) {
      return this.GetItems(category)
        .filter(v => GetText(v.id).toUpperCase().indexOf(this.filters[category].toUpperCase()) !== -1)
    },
    ClearIconClick (category) {
      this.filters[category] = ''
    }
  }
}
</script>

<style scoped>
.item {
  outline: 1px solid darkcyan;
  font-size: 1.2em;
  padding: 5px;
}

.item:hover {
  background-color: lightgray;
  cursor: pointer;
}

</style>
